import { Skeleton, Stack } from '@chakra-ui/react';

export const SkeletonContent = () => {
  return (
    <Stack>
      {[...Array(10)].map((_, index) => (
        <Skeleton key={index} w={'full'} h={'58px'} borderRadius={'10px'} />
      ))}
    </Stack>
  );
};
