import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import credit from '../../../assets/images/credit-analysis.svg';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsCreditAnalysis } from '../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';

const CreditAnalysisPage = () => {
  const hasPermission = permissions.vendemmiaCreditAnalysis;

  const request = useRef(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} análises`;

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listCreditAnalysis(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsCreditAnalysis);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="credit"
      title="Análise de Crédito"
      breadcrumbIcon={credit}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="CNPJ, Nome Fantasia, Razão Social"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar análise de crédito',
          text: 'Cadastro de análise de crédito',
          action: (
            <Button
              bgColor="green"
              color="white"
              pt="23px"
              pb="23px"
              borderRadius="50px"
              _hover={{ bgColor: '#70D499' }}
              onClick={() => {
                onOpen();
              }}>
              <MdAdd color="#FFFFFF" size={20} />
              <Text textStyle="paragraph" ml="10px">
                Nova análise
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
    </Page>
  );
};

export default CreditAnalysisPage;
