import profile from './profile';

var permissionsIds = [];

function populate(userPermissions) {
  userPermissions.map((item) => {
    return permissionsIds.push(item.id);
  });
}
populate(profile.permissions);

const isSystemAdmin = profile.role === 'ROLE_SYSTEM_ADMIN';

const permissions = {
  isSystemAdmin,

  comexProcess: isSystemAdmin || permissionsIds.includes(74) || permissionsIds.includes(1),
  comexKanban: isSystemAdmin || permissionsIds.includes(74) || permissionsIds.includes(48),
  comexAgenda: isSystemAdmin || permissionsIds.includes(74) || permissionsIds.includes(50),
  comexMapping: isSystemAdmin || permissionsIds.includes(74) || permissionsIds.includes(71),
  canHideProcess: isSystemAdmin || permissionsIds.includes(63),

  warehouseReceipt: isSystemAdmin || permissionsIds.includes(56),
  warehouseStock: isSystemAdmin || permissionsIds.includes(57),
  warehouseStockPositionConsolidationUpload: isSystemAdmin || permissionsIds.includes(104),
  warehousePickingAndPacking: isSystemAdmin || permissionsIds.includes(58),
  warehouseExpedition: isSystemAdmin || permissionsIds.includes(59),
  warehouseExpeditionDocumentsManagement: isSystemAdmin || permissionsIds.includes(108),
  warehouseExpeditionDocumentsView: isSystemAdmin || permissionsIds.includes(108) || permissionsIds.includes(109),
  warehouseStockAging: isSystemAdmin || permissionsIds.includes(110),

  logisticRealtime: isSystemAdmin || permissionsIds.includes(75),
  logisticTripFullManagement: isSystemAdmin || permissionsIds.includes(76),
  logisticTripView: isSystemAdmin || permissionsIds.includes(76) || permissionsIds.includes(91),
  logisticTripCreate: isSystemAdmin || permissionsIds.includes(76) || permissionsIds.includes(92),
  logisticTripEdit: isSystemAdmin || permissionsIds.includes(76) || permissionsIds.includes(93),
  logisticTripChangeStatus: isSystemAdmin || permissionsIds.includes(76) || permissionsIds.includes(94),
  logisticDriverManagement: isSystemAdmin || permissionsIds.includes(77),
  logisticFleetManagement: isSystemAdmin || permissionsIds.includes(78),
  logisticDocumentView: isSystemAdmin || permissionsIds.includes(111) || permissionsIds.includes(76),
  logisticDocumentManagement: isSystemAdmin || permissionsIds.includes(112) || permissionsIds.includes(76),

  metricsComex: isSystemAdmin || permissionsIds.includes(49),
  metricsWarehouse: isSystemAdmin || permissionsIds.includes(80),
  metricsKpiComex: isSystemAdmin || permissionsIds.includes(81),
  metricsKpiWarehouse: isSystemAdmin || permissionsIds.includes(82),

  vendemmiaFreightQuote: isSystemAdmin || permissionsIds.includes(54),
  vendemmiaOperationSpecificationManagement: isSystemAdmin || permissionsIds.includes(84),
  vendemmiaOperationSpecificationView: permissionsIds.includes(107),
  vendemmiaCreditAnalysis: isSystemAdmin || permissionsIds.includes(102),
  vendemmiaContractManagement: isSystemAdmin || permissionsIds.includes(103),
  vendemmiaMIA: isSystemAdmin || permissionsIds.includes(85),
  vendemmiaSQLReportView: isSystemAdmin || permissionsIds.includes(96),
  vendemmiaTaxInquiry: isSystemAdmin || permissionsIds.includes(100),
  vendemmiaCertificate: isSystemAdmin || permissionsIds.includes(99),
  vendemmiaComexCashFlow: isSystemAdmin || permissionsIds.includes(97),
  vendemmiaProfitability: isSystemAdmin || permissionsIds.includes(101),
  vendemmiaBillingDashboard: isSystemAdmin || permissionsIds.includes(106),

  vendemmiaManagementItReports: isSystemAdmin || permissionsIds.includes(87),
  vendemmiaManagementUserManagement: isSystemAdmin || permissionsIds.includes(88),
  vendemmiaManagementClientManagement: isSystemAdmin || permissionsIds.includes(113),
  vendemmiaManagementFaqManagement: isSystemAdmin || permissionsIds.includes(89),
  vendemmiaManagementPolicy: isSystemAdmin || permissionsIds.includes(90),
  vendemmiaSQLReportManagement: isSystemAdmin || permissionsIds.includes(95),
  vendemmiaAdditionalFieldsManagement: isSystemAdmin || permissionsIds.includes(98),
  vendemmiaNcmManagement: isSystemAdmin || permissionsIds.includes(101),
};

permissions.comex = permissions.comexProcess || permissions.comexKanban || permissions.comexAgenda || permissions.comexMapping;
permissions.warehouse =
  permissions.warehouseReceipt ||
  permissions.warehouseStock ||
  permissions.warehouseStockPositionConsolidationUpload ||
  permissions.warehousePickingAndPacking ||
  permissions.warehouseExpedition ||
  permissions.warehouseExpeditionDocumentsManagement ||
  permissions.warehouseExpeditionDocumentsView ||
  permissions.warehouseStockAging;
permissions.logistic =
  permissions.logisticRealtime ||
  permissions.logisticTripFullManagement ||
  permissions.logisticTripView ||
  permissions.logisticTripCreate ||
  permissions.logisticTripEdit ||
  permissions.logisticTripChangeStatus ||
  permissions.logisticDriverManagement ||
  permissions.logisticFleetManagement;
permissions.metrics =
  permissions.metricsComex || permissions.metricsWarehouse || permissions.metricsKpiComex || permissions.metricsKpiWarehouse;
permissions.vendemmia =
  permissions.vendemmiaFreightQuote ||
  permissions.vendemmiaOperationSpecificationManagement ||
  permissions.vendemmiaOperationSpecificationView ||
  permissions.vendemmiaBillingDashboard ||
  permissions.vendemmiaProfitability ||
  permissions.vendemmiaMIA ||
  permissions.vendemmiaCreditAnalysis ||
  permissions.vendemmiaContractManagement ||
  permissions.vendemmiaSQLReportManagement ||
  permissions.vendemmiaSQLReportView ||
  permissions.vendemmiaTaxInquiry ||
  permissions.vendemmiaCertificate ||
  permissions.vendemmiaComexCashFlow;
permissions.vendemmiaManagement =
  permissions.vendemmiaManagementItReports ||
  permissions.vendemmiaManagementUserManagement ||
  permissions.vendemmiaManagementFaqManagement ||
  permissions.vendemmiaManagementPolicy ||
  permissions.vendemmiaSQLReportManagement ||
  permissions.vendemmiaAdditionalFieldsManagement ||
  permissions.vendemmiaManagementClientManagement ||
  permissions.vendemmiaNcmManagement;

export default permissions;
