import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Button, Collapse, Flex, useDisclosure } from '@chakra-ui/react';

import CommonListItemLine from './CommonListItemLine';
import CommonListTable from './templates/CommonListTable';

const CommonListItemContent = ({ ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  //default properties
  const { list, categories } = props;

  const children = props?.content;
  const identifier = props?.identifier;

  //data
  const data = props?.data;

  //order
  const order = props.order;
  const orderChildren = props.orderChildren;

  //collapsed
  const hasCollapsed = props?.hasCollapsed;
  const collapsedContentType = props?.collapsedContentType;

  //status
  const tags = props?.tags;
  const statusPreset = props?.statusPreset;

  //images
  const images = props?.images;
  const imagePreset = props?.imagePreset;

  //instance
  const item = props?.item;
  const rawItem = props?.rawItem;
  const listIndex = props.listIndex;

  //loader
  const isLoading = props?.isLoading;

  //category
  const tooltipCategories = props?.tooltipCategories;

  //subcategories
  const subList = props?.subList;
  const pathSublist = props?.pathSublist;
  const subcategories = props?.subcategories;

  //buttonDetails
  const hasDetailsButton = props?.hasDetailsButton;
  const detailsRedirectEndpoint = props?.detailsRedirectEndpoint;
  const hasDetailsButtonSublist = props?.hasDetailsButtonSublist;
  const detailsRedirectEndpointSublist = props?.detailsRedirectEndpointSublist;
  //buttonDelete
  const hasDeleteButton = props?.hasDeleteButton;
  const deleteButtonAction = props?.deleteButtonAction;
  //customButtons
  const customButtons = props?.customButtons;
  const customButtonsPrimary = customButtons && customButtons[0]?.main;
  const customButtonsSecundary = customButtons && customButtons[0]?.collapsed;

  //customFields
  const customFields = props?.customFields;

  //responsiveness
  const isMobile = props?.isMobile;
  const deviceType = props?.deviceType;
  const viewport = props?.viewport;
  const gridTemplateLength = props?.gridTemplateLength;

  //messages
  const emptyListMessage = props?.emptyListMessage;

  //resume
  const maxLengthResume = props?.maxLengthResume;
  const maxLengthResumeChildren = props?.maxLengthResumeChildren;

  //lenght
  //vazio trás a lista em primeiro nivel, se nao, em segundo.
  const sublistPathItens = pathSublist != '' ? data[listIndex][pathSublist] : [data[listIndex]];
  const sublistLength = sublistPathItens && sublistPathItens.length > 0;

  //rowSize
  const cellRowSize = props?.cellRowSize;
  const resizeRowsChildren = props?.resizeRowsChildren;

  return (
    <>
      {hasCollapsed && sublistLength ? (
        //exibição dos itens com colapso
        <Button variant="reset" textAlign={'left'} color={'black-400'} lineHeight={'40px'} onClick={onToggle} position={'relative'}>
          <CommonListItemLine
            list={list}
            categories={categories}
            item={item}
            rawItem={rawItem}
            content={children}
            gridTemplateLength={gridTemplateLength}
            hasDetailsButton={hasDetailsButton}
            detailsRedirectEndpoint={detailsRedirectEndpoint}
            hasDeleteButton={hasDeleteButton}
            deleteButtonAction={deleteButtonAction}
            customButtons={customButtonsPrimary}
            customFields={customFields}
            identifier={identifier}
            isMobile={isMobile}
            deviceType={deviceType}
            viewport={viewport}
            maxLengthResume={maxLengthResume}
            listIndex={listIndex}
            tags={tags}
            statusPreset={statusPreset}
            images={images}
            imagePreset={imagePreset}
            data={data}
            cellRowSize={cellRowSize}
            order={order}
            isLoading={isLoading}
          />
          <Flex data-collapsed>{isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}</Flex>
        </Button>
      ) : (
        //exibição dos itens sem colapso
        <CommonListItemLine
          list={list}
          categories={categories}
          item={item}
          rawItem={rawItem}
          content={children}
          gridTemplateLength={gridTemplateLength}
          hasDetailsButton={hasDetailsButton}
          detailsRedirectEndpoint={detailsRedirectEndpoint}
          hasDeleteButton={hasDeleteButton}
          deleteButtonAction={deleteButtonAction}
          customButtons={customButtonsPrimary}
          customFields={customFields}
          identifier={identifier}
          isMobile={isMobile}
          deviceType={deviceType}
          viewport={viewport}
          maxLengthResume={maxLengthResume}
          listIndex={listIndex}
          tags={tags}
          statusPreset={statusPreset}
          images={images}
          imagePreset={imagePreset}
          data={data}
          cellRowSize={cellRowSize}
          order={order}
          isLoading={isLoading}
        />
      )}

      {
        //escolha da exibição do template do colapso
        hasCollapsed && (
          <Collapse in={isOpen} animateOpacity>
            {collapsedContentType === 'table' && (
              //As props à abaixo são necessárias pra renderizar o proprio componente de tabela

              <CommonListTable
                content={children}
                gridTemplateLength={gridTemplateLength}
                isMobile={isMobile}
                deviceType={deviceType}
                viewport={viewport}
                identifier={identifier} // envia o identificador para a lista mapear a posição
                categories={categories}
                hasDetailsButton={hasDetailsButtonSublist}
                detailsRedirectEndpoint={detailsRedirectEndpointSublist}
                deleteButtonAction={deleteButtonAction}
                customButtons={customButtonsSecundary}
                customFields={customFields}
                pathSublist={sublistPathItens}
                subcategories={subcategories}
                maxLengthResume={maxLengthResumeChildren}
                tooltipCategories={tooltipCategories}
                emptyListMessage={emptyListMessage}
                subList={subList}
                listIndex={listIndex}
                sublistPathItens={sublistPathItens}
                tags={tags}
                statusPreset={statusPreset}
                images={images}
                imagePreset={imagePreset}
                data={data}
                cellRowSize={cellRowSize}
                resizeRowsChildren={resizeRowsChildren}
                order={orderChildren}
                isLoading={isLoading}
              />
            )}
          </Collapse>
        )
      }
    </>
  );
};

export default CommonListItemContent;
