import React, { useState } from 'react';

import { FaMailBulk } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';

import BackgroundLogin from '../../../../components/Login/BackgroundLogin/BackgroundLogin';

const SignatureResultPage = () => {
  const location = useLocation();
  const dataState = location.state;
  const [userData, setUserData] = useState(
    window.localStorage.getItem('data-signature')?.length > 0 ? JSON.parse(window.localStorage.getItem('data-signature')) : null
  );

  const currentDateTime = new Date();
  const timestamp = currentDateTime.getTime();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <BackgroundLogin maxW="auto" showLogo={false}>
      <style>
        {`
            li {
                 list-style: inherit;
            }
        `}
      </style>
      <Flex direction="column" w="full">
        <Flex direction="column" w="full" bgColor="#FFFFFF">
          <Text textStyle="subtitle" textColor="#FFFFFF">
            A assinatura foi criada, agora basta copiar e adicionar ao seu email!
          </Text>

          <iframe
            sandbox
            id="iframe"
            width="100%"
            style={{
              height: '45vh',
            }}
            src={`https://assinaturas.vendemmia.com.br/v2/generate.php?name=${userData?.name}&description=${userData?.role}&email=${userData?.email}&phone=${userData?.phone}&address=${userData?.address}&v=${timestamp}`}
          />
        </Flex>

        <Flex
          w="full"
          mt={{ sm: '20px', lg: '30px' }}
          gap={{ sm: '0px', lg: '15px' }}
          justify={{ sm: 'space-between', lg: 'end' }}
          className="down-anim">
          <Button
            variant="outline"
            height="40px"
            borderRadius="5px"
            borderColor="#FF2F69"
            textColor="#FF2F69"
            transition=".3s"
            _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
            onClick={() => {
              window.location.href = '/';
            }}>
            <Text>Voltar</Text>
          </Button>

          <Button
            variant="solid"
            height="40px"
            borderRadius="5px"
            bgColor="#FF2F69"
            textColor="#FFFFFF"
            transition=".3s"
            _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
            onClick={() => onOpen()}>
            <FaMailBulk />

            <Text ml="10px">Como configuro?</Text>
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent h="auto" w={{ base: '90vw', lg: '60vw' }} maxW="none" overflowY="auto">
          <ModalHeader>
            <Flex align="center" justify="space-between">
              <Text textStyle="tableTitle" textColor="primary" fontWeight="bold">
                Como configurar sua assinatura
              </Text>
            </Flex>

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody p={{ base: '20px', lg: '50px' }}>
            <OrderedList>
              <ListItem>
                Selecione com o <kbd>mouse</kbd> todo o conteúdo da assinatura gerada ou clique dentro da caixa com a assinatura e
                aperte as teclas <kbd>ctrl + a</kbd>.
              </ListItem>
              <ListItem>
                Copie o conteúdo selecionado (com as teclas <kbd>ctrl + c</kbd>, ou com o botão direito do mouse e clicando em copiar).
              </ListItem>
              <ListItem>
                Escolha uma das opções abaixo para aplicar sua assinatura:
                <UnorderedList styleType="lower-alpha">
                  <ListItem>
                    Se você estiver com o <b>outlook instalado</b> no computador
                    <UnorderedList>
                      <ListItem>{`Acesse, no canto superior esquerdo, "Arquivo" > "Opções" > "E-mail" > "Assinatura".`}</ListItem>
                      <ListItem>Preencha o espaço da assinatura colando o que foi copiado.</ListItem>
                    </UnorderedList>
                  </ListItem>
                  <ListItem>
                    Se você usa a <b>versão online</b>, no navegador
                    <UnorderedList>
                      <ListItem>Acesse, no canto superior direito a engrenagem de configurações</ListItem>
                      <ListItem>{`Use a caixa de pesquisa para pesquisar "Assinatura"`}</ListItem>
                      <ListItem>{`Abra o item "Assinatura de Email"`}</ListItem>
                      <ListItem>Preencha o espaço da assinatura colando o que foi copiado.</ListItem>
                    </UnorderedList>
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                <b>Importante:</b> Se tiver que corrigir alguma informação, não altere direto em seu e-mail. Corrija pelo formulário da
                página anterior e gere novamente a sua assinatura.
              </ListItem>
            </OrderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BackgroundLogin>
  );
};

export default SignatureResultPage;
