import { Button, Tooltip } from '@chakra-ui/react';

const shouldRenderButton = (buttonProps, type = true) => {
  return typeof type === 'function' ? type(buttonProps) : type;
};

const CustomButtons = ({ buttons, buttonProps, identifier, subCategoriesProps }) => {
  return buttons?.map((button, key) => {
    //isButtonVisible
    const isVisible = shouldRenderButton(buttonProps, button.isButtonVisible);

    if (!isVisible) {
      return null;
    }
    //isButtonDisabled
    const { isButtonDisabled = false } = button;
    const isDisabled = shouldRenderButton(buttonProps, isButtonDisabled);

    return (
      button.tooltip && (
        <Tooltip key={key} label={button.tooltip ?? ''}>
          <Button
            p={!button.icon ? '0 15px' : '0'}
            variant={'commonTable'}
            onClick={(e) => {
              e.stopPropagation();
              button.action(identifier, subCategoriesProps);
            }}
            isDisabled={isDisabled ? isDisabled : false}>
            {button.icon ? button.icon : button.label}
          </Button>
        </Tooltip>
      )
    );
  });
};

export default CustomButtons;
