import { useEffect, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';

import { useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import { commonListConfig } from './components/commonListConfig';
import ModalManagementClient from './components/ModalManagementClient';

const ClientManagementPage = () => {
  const hasPermission = permissions.vendemmiaManagementClientManagement;

  const [isMobile, setIsMobile] = useState(false);

  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [identifier, setIdentifier] = useState();

  const [list, setList] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const load = (filters, key) => {
    fetchClientListData(filters, metadata.current_page, 25);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const fetchClientListData = async (filters, page, pageSize) => {
    setIsLoading(true);

    try {
      const res = await requests.listClients(filters, page, pageSize, 'all');
      setList(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoading(false);
  };

  const subtitle = `Existe um total de ${metadata.total_count} clientes`;

  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const handleOpenEditModal = (identifier) => {
    onEditModalOpen();
    setIdentifier(identifier);
  };

  const handleEditModalClose = () => {
    onEditModalClose();
    load();
  };

  //component preset
  const customButtons = [
    {
      main: [
        { label: 'Prévia', tooltip: 'Visualizar cliente', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenEditModal },
      ],
      collapsed: [],
    },
  ];
  //component preset

  useEffect(() => {
    load();
  }, [action]);

  return (
    <Page
      screen="client-management"
      title={`Gestão de clientes`}
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por nome ou CNPJ"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      showPeriodFilter={false}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      isRefreshLoading={isLoading}
      showFilters={false}
      showRefreshData={false}
      allowFiltersOverride={false}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      allowEmptySearchPeriod={true}
      allowEmptySearchFilterData={true}>
      <ModalManagementClient
        triggerRefresh={triggerRefresh}
        identifier={identifier}
        handleClose={handleEditModalClose}
        handleOpen={onEditModalOpen}
        isOpen={isEditModalOpen}
      />

      <CommonList
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //custom
        customButtons={customButtons}
        //others
        subtitle={subtitle}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
    </Page>
  );
};

export default ClientManagementPage;
