import React, { useEffect, useRef, useState } from 'react';

import { MdHelp, MdOutlineReceiptLong, MdRemoveRedEye } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import OrderTrackingChart from '../../../components/Charts/Warehouse/OrderTrackingChart';
import ProductTrackingChart from '../../../components/Charts/Warehouse/ProductTrackingChart';
import ReceiptDeadlinesChart from '../../../components/Charts/Warehouse/ReceiptDeadlinesChart';
import ShipmentAnalysisChart from '../../../components/Charts/Warehouse/ShipmentAnalysisChart';
import WerehouseSlaChart from '../../../components/Charts/Warehouse/WerehouseSlaChart';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import { filterOptionsWarehouseReceipt } from '../../../utils/filters/filterPresets';

import { commonListConfig } from './components/commonListConfig';
import DetailsOR from './components/DetailsOR';

const ReceiptPage = () => {
  const hasPermission = permissions.warehouseReceipt;

  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoadingChart, setIsLoadingChart] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState();
  const [sublist, setSublist] = useState();

  const [chartData, setChartData] = useState({});

  const [statusGrafico, setStatusGrafico] = useState(null);
  const [statusPedido, setStatusPedido] = useState(null);

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //modals methods
  const { isOpen: modalDetailsORIsOpen, onOpen: modalDetailsOROnOpen, onClose: modalDetailsOROnClose } = useDisclosure();

  //fields
  const [item, setItem] = useState();

  const load = async (filters, key, page) => {
    setIsLoading(true);

    var filters = {
      ...filters,
      statusGrafico,
      statusPedido,
    };

    try {
      const res = await requests.listRecebimento(filters, page);
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(res.data);
      setSublist(res.data);
      setMetadata(res.meta);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    requests
      .dashboardRecebimento(filters)
      .then((data) => {
        if (requestChart.current && requestChart.current > key) {
          return;
        }
        requestChart.current = key;

        setChartData(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABAction = (filters, key) => {
    var filters = {
      ...filters,
      statusGrafico,
      statusPedido,
    };

    //preset
    const extension = `xlsx`;
    const endpoint = `/recebimento/download/recebimento`;
    const fileName = `Recebimento`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouseReceipt());
  };

  const chartList = [
    {
      title: 'Prazos de recebimento',
      content: <ReceiptDeadlinesChart data={chartData} setStatusGrafico={setStatusGrafico} />,
      hasModal: false,
    },
    {
      title: 'Tracking de produtos',
      content: <ProductTrackingChart data={chartData} setStatusPedido={setStatusPedido} />,
      hasModal: false,
    },
    {
      title: 'Tracking de pedidos',
      content: <OrderTrackingChart data={chartData} setStatusPedido={setStatusPedido} />,
      hasModal: false,
    },
    {
      title: 'Análise de remessas',
      content: <ShipmentAnalysisChart data={chartData} />,
      hasModal: true,
      helpTooltip: (
        <Tooltip label="Análise de remessa = soma total dos itens / período de expedição">
          <Box ml="0.325rem">
            <MdHelp size={15} color="#422C76" />
          </Box>
        </Tooltip>
      ),
      modal: (
        <WerehouseSlaChart data={chartData} type="receipt" modality="byPeriod" onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
      ),
    },
  ];

  //view detailsOR
  const handleOpenModalView = (id, data) => {
    setItem(data);
    modalDetailsOROnOpen();
  };

  const customButtons = [
    {
      main: [
        {
          label: 'Detalhes OR',
          tooltip: 'Visualizar',
          action: handleOpenModalView,
          /* isButtonDisabled: true, */
        },
      ],
      collapsed: [],
    },
  ];

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico, statusPedido]);

  return (
    <Page
      screen="warehouse-receipt"
      title="Recebimento"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou OR"
      filterOptions={filterOptions}
      hasPermission={hasPermission}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading || isLoadingChart}
      list={list}
      load={load}
      loadCharts={loadCharts}
      metadata={metadata}
      setIsMobile={setIsMobile}
      sorting={sorting}
      hasChartChanges={hasChartChanges}
      FAB={[
        {
          title: 'Exportar recebimento',
          text: 'Exportar arquivo CSV com dados de recebimento',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="20px" m="10px">
        <Grid w="full" h={{ base: 'initial', md: '420px' }}>
          <Accordion display="grid" gap="5px" gridTemplateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}>
            {chartList.map((item, key) => {
              return !item.hasModal ? (
                !isMobile ? (
                  /* desktop */
                  <Card key={key} title={item.title}>
                    <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                  </Card>
                ) : (
                  /* mobile */
                  <AccordionItem key={key}>
                    <Card>
                      <AccordionButton display="flex" justifyContent="space-between" _hover="none">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel>
                        <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                      </AccordionPanel>
                    </Card>
                  </AccordionItem>
                )
              ) : /* modal */
              !isMobile ? (
                /* desktop */
                <Card
                  key={key}
                  header={
                    <Flex justify="space-between" w="full" alignItems="center">
                      <Flex justify="center">
                        <Text textStyle="cardTitle" justifyContent="center">
                          {item.title}
                        </Text>
                        {item?.helpTooltip}
                      </Flex>

                      {!isLoadingChart && (
                        <Tooltip label={item.title}>
                          <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                        </Tooltip>
                      )}
                    </Flex>
                  }>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <Flex direction="row" justify="center" h="full">
                      {item.content}

                      {item?.modal}
                    </Flex>
                  </ScreenLoader>
                </Card>
              ) : (
                /* mobile */
                <AccordionItem key={key}>
                  <Card
                    borderBottom="0"
                    header={
                      <Flex justify="space-between" w="full" alignItems="center">
                        <Text textStyle="cardTitle">
                          <AccordionButton _hover="none" p="8px 5px">
                            <Text textStyle="cardTitle" fontSize="16px">
                              {item.title}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>

                        {!isLoadingChart && (
                          <Tooltip label={item.title}>
                            <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                          </Tooltip>
                        )}
                      </Flex>
                    }>
                    <ScreenLoader isLoading={isLoadingChart}>
                      <Flex direction="row" justify="center" h="full">
                        <AccordionPanel>{item.content}</AccordionPanel>

                        {item?.modal}
                      </Flex>
                    </ScreenLoader>
                  </Card>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Grid>

        <CommonList
          //lists
          list={list}
          rawData={list}
          subList={sublist}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          //custom
          customButtons={customButtons}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />

        {/* modal view detailsOR */}
        <CommonModal
          heading={'Detalhes do recebimento'}
          icon={<MdOutlineReceiptLong color="#6C48C2" size="35px" opacity="0.6" />}
          /* subtitle={`Criado em: ${moment(createdAt).format('DD/MM/YYYY ')}`} */
          isOpen={modalDetailsORIsOpen}
          onOpen={modalDetailsOROnOpen}
          onClose={modalDetailsOROnClose}
          variant={'clear'}
          fullpage={true}
          removeRadius={false}>
          <DetailsOR entity={item} />
        </CommonModal>
      </Flex>
    </Page>
  );
};

export default ReceiptPage;
