import React, { useEffect, useRef, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd, MdOutlineModeEditOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsOperationSpecification } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';

const OperationSpecification = () => {
  const hasPermissionView = permissions.vendemmiaOperationSpecificationView;
  const hasManagementPermission = permissions.vendemmiaOperationSpecificationManagement;

  //fields
  const [identifier, setIdentifier] = useState();

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  //modals methods
  const { isOpen: modalDeleteIsOpen, onOpen: modalDeleteOnOpen, onClose: modalDeleteOnClose } = useDisclosure();

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} registros`;

  const handleNewContract = () => {
    localStorage.removeItem('edit');
  };

  /* const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  }; */

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listOperation(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  // Utilizção do hook personalizado para o refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsOperationSpecification());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  /* const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .importOperationNcmsAction(docs, config)
        .then(() => {
          resolve();
          Toast.success('Arquivo importado com sucesso.');
        })
        .catch(() => {
          reject();
        });
    });
  }; */

  const handleEditContract = (identifier, _data) => {
    localStorage.setItem('edit', true);
    window.location.href = `/vendemmia/operation-specification/detail/${identifier}`;
  };

  const handleDeleteContract = (identifier, _data) => {
    setIdentifier(identifier);
    modalDeleteOnOpen();
  };

  //component preset
  const customButtons = [
    {
      main: [
        {
          label: 'Editar',
          tooltip: 'Editar especificação',
          icon: <MdOutlineModeEditOutline size={20} />,
          action: handleEditContract,
          isButtonDisabled: !hasManagementPermission,
        },
        {
          label: 'Excluir',
          tooltip: 'Excluir especificação',
          icon: <FaTrashAlt size={16} color="#E74C3C" />,
          action: handleDeleteContract,
          isButtonDisabled: (item) => !!((hasManagementPermission || hasPermissionView) && item.status !== 'RASCUNHO'),
        },
      ],
      collapsed: [],
    },
  ];
  //component preset

  return (
    <Page
      screen="operation-specificaiton"
      title="Especificação operacional"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Pesquise pelo conteúdo..."
      setIsMobile={setIsMobile}
      hasPermission={hasPermissionView || hasManagementPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      showFilters={true}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={
        hasManagementPermission && [
          {
            title: 'Cadastrar especificação operacional',
            text: 'Cadastro de especificação operacional',
            action: (
              <Link onClick={handleNewContract} to="/vendemmia/operation-specification/new" key={2}>
                <Button bgColor="green" color="white" pt="23px" pb="23px" borderRadius="50px" _hover={{ bgColor: '#70D499' }}>
                  <MdAdd color="#FFFFFF" size={20} />
                  <Text textStyle="paragraph" ml="10px">
                    Nova especificação operacional
                  </Text>
                </Button>
              </Link>
            ),
            modality: 'custom',
          },
        ]
      }>
      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //custom
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />

      {/* delete */}
      <CommonModal
        heading={'Deseja mesmo excluir esse contrato?'}
        subtitle={'Ele sumirá automaticamente da lista de contrato.'}
        callback={() =>
          executeRequest(
            () => requests.deleteOperation(identifier),
            'Especificação operacional removida com sucesso',
            'Falha remover contrato, tente novamente ou entre em contato com o Administrador',
            setIsLoading,
            triggerRefresh
          )
        }
        isOpen={modalDeleteIsOpen}
        onOpen={modalDeleteOnOpen}
        onClose={modalDeleteOnClose}
        variant={'caution'}
        size={'md'}
        removeRadius={true}
      />
    </Page>
  );
};

export default OperationSpecification;
