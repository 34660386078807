import { MdKeyboardArrowDown } from 'react-icons/md';

import permissions from '../../services/permissions';
import {
  aditionalfields,
  agenda,
  ai,
  analitycs,
  armazem,
  contractManagement,
  cotacao,
  creditAnalysis,
  defaultIcon,
  driver,
  estoque,
  expedicao,
  expeditionDocuments,
  gestaoViagens,
  kanban,
  kpi,
  mapa,
  operacao,
  overview,
  pickingPacking,
  processos,
  recebimento,
  relatorioTi,
  timeline,
  userManagement,
  vehicle,
} from '../../utils/exports';

const items = [];

if (permissions.comex) {
  items.push({
    title: 'Visão geral',
    link: '/dashboard',
    icon: overview,
  });

  let aux = [];

  if (permissions.comexProcess) {
    aux.push({
      title: 'Processos',
      subTitle: 'Timeline completa dos processos',
      link: '/comex/process',
      icon: processos,
    });
  }
  if (permissions.comexKanban) {
    aux.push({
      title: 'Kanban',
      subTitle: 'Veja os processos por etapas',
      link: '/comex/kanban',
      icon: kanban,
    });
  }
  if (permissions.comexAgenda) {
    aux.push({
      title: 'Agenda',
      subTitle: 'Veja seu processo por data',
      link: '/comex/calendar',
      icon: agenda,
    });
  }
  if (permissions.comexMapping) {
    aux.push({
      title: 'Mapeamento',
      subTitle: 'Extraia os dados de seus processos',
      link: '/comex/mapping',
      icon: mapa,
    });
  }

  if (
    !permissions.warehouse &&
    !permissions.logistic &&
    !permissions.metrics &&
    !permissions.vendemmia &&
    !permissions.vendemmiaManagement
  ) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Importação',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Dados de importação',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

if (permissions.warehouse) {
  let aux = [];
  if (permissions.warehouseReceipt) {
    aux.push({
      title: 'Recebimento',
      subTitle: 'Veja os dados de recebimento',
      link: '/warehouse/receipt',
      icon: recebimento,
    });
  }
  if (permissions.warehouseStock) {
    aux.push({
      title: 'Estoque',
      subTitle: 'Analise o seu estoque atual',
      link: '/warehouse/stock',
      icon: estoque,
    });
  }
  if (permissions.warehousePickingAndPacking) {
    aux.push({
      title: 'Picking e Packing',
      subTitle: 'Andamento da estratégia de separação',
      link: '/warehouse/picking-packing',
      icon: pickingPacking,
    });
  }
  if (permissions.warehouseExpedition) {
    aux.push({
      title: 'Expedição',
      subTitle: 'Veja os números da expedição',
      link: '/warehouse/expedition',
      icon: expedicao,
    });
  }

  if (permissions.warehouseExpeditionDocumentsView || permissions.warehouseExpeditionDocumentsManagement) {
    aux.push({
      title: 'Documentos de expedição',
      subTitle: 'Acesse os arquivos de romaneios e canhotos',
      link: '/warehouse/expedition/documents',
      icon: expeditionDocuments,
    });
  }

  if (permissions.warehouseExpeditionDocumentsView) {
    aux.push({
      title: 'Aging de estoque',
      subTitle: 'Analise o indicador de tempo por grupo/família de produtos',
      link: '/warehouse/stock-aging',
      icon: recebimento,
    });
  }

  if (
    !permissions.comex &&
    !permissions.logistic &&
    !permissions.metrics &&
    !permissions.vendemmia &&
    !permissions.vendemmiaManagement
  ) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Armazém',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Dados de armazém',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

if (permissions.logistic) {
  let aux = [];
  if (permissions.logisticRealtime) {
    aux.push({
      title: 'Tempo real',
      subTitle: 'Acompanhe as viagens atuais',
      link: '/logistic/trip/timeline',
      icon: timeline,
    });
  }

  if (permissions.logisticTripFullManagement) {
    aux.push({
      title: 'Gestão de viagens',
      subTitle: 'Viagens atuais, passadas e futuras',
      link: '/logistic/trip',
      icon: gestaoViagens,
    });
  } else if (permissions.logisticTripView) {
    aux.push({
      title: 'Visualização de viagens',
      subTitle: 'Viagens atuais, passadas e futuras',
      link: '/logistic/trip',
      icon: gestaoViagens,
    });
  }

  if (permissions.logisticDriverManagement) {
    aux.push({
      title: 'Gestão de motorista',
      subTitle: 'Valide e trate os dados dos motoristas',
      link: '/logistic/drivers',
      icon: driver,
    });
  }
  if (permissions.logisticFleetManagement) {
    aux.push({
      title: 'Gestão de frota',
      subTitle: 'Acompanhe e cuide de sua frota',
      link: '/logistic/fleet',
      icon: vehicle,
    });
  }

  if (
    !permissions.comex &&
    !permissions.warehouse &&
    !permissions.metrics &&
    !permissions.vendemmia &&
    !permissions.vendemmiaManagement
  ) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Transporte',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Acompanhe suas viagens',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

if (permissions.metrics) {
  let aux = [];
  if (permissions.metricsComex) {
    aux.push({
      title: 'Importação',
      subTitle: 'Painel geral de métricas para importação',
      link: '/metrics/analytics',
      icon: analitycs,
    });
  }
  if (permissions.metricsWarehouse) {
    aux.push({
      title: 'Armazém',
      subTitle: 'Painel geral de métricas para armazém',
      link: '/metrics/warehouse',
      icon: armazem,
    });
  }
  if (permissions.metricsKpiComex) {
    aux.push({
      title: 'KPI importação',
      subTitle: 'Métricas especializadas para importação',
      link: '/metrics/kpi-comex',
      icon: kpi,
    });
  }
  if (permissions.metricsKpiWarehouse) {
    aux.push({
      title: 'KPI armazém',
      subTitle: 'Métricas especializadas para armazém',
      link: '/metrics/kpi-warehouse',
      icon: kpi,
    });
  }

  if (
    !permissions.comex &&
    !permissions.logistic &&
    !permissions.warehouse &&
    !permissions.vendemmia &&
    !permissions.vendemmiaManagement
  ) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Métricas',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Navegue por métrica',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

if (permissions.vendemmia) {
  let aux = [];

  if (permissions.vendemmiaFreightQuote) {
    aux.push({
      title: 'Cotação de frete',
      subTitle: 'Gestão das cotações de frete',
      link: '/vendemmia/quote',
      icon: cotacao,
    });
  }

  if (permissions.vendemmiaCertificate) {
    aux.push({
      title: 'Certificado Digital',
      subTitle: 'Automatização com certificado A1',
      link: '/vendemmia/certificate',
      icon: operacao,
    });
  }

  if (permissions.vendemmiaMIA) {
    aux.push({
      title: 'Fale com a Mia',
      subTitle: 'Uma assistente para suas dúvidas',
      link: '/vendemmia/ai-assistant',
      icon: ai,
    });
  }

  if (permissions.vendemmiaCreditAnalysis) {
    aux.push({
      title: 'Análise de Crédito',
      subTitle: 'Análise de crédito da empresa',
      link: '/vendemmia/credit-analysis',
      icon: creditAnalysis,
    });
  }

  if (permissions.vendemmiaOperationSpecificationManagement) {
    aux.push({
      title: 'Espec. Operacional',
      subTitle: 'Gestão das especificações operacionais',
      link: '/vendemmia/operation-specification',
      icon: operacao,
    });
  }

  if (permissions.vendemmiaOperationSpecificationView) {
    aux.push({
      title: 'Espec. Operacional',
      subTitle: 'Visualização das especificações operacionais',
      link: '/vendemmia/operation-specification',
      icon: operacao,
    });
  }

  if (permissions.vendemmiaTaxInquiry) {
    aux.push({
      title: 'Consulta tributária',
      subTitle: 'Veja os estudos de NCM',
      link: '/vendemmia/tax-inquiry',
      icon: processos,
    });
  }

  if (permissions.vendemmiaComexCashFlow) {
    aux.push({
      title: 'Fluxo de caixa',
      subTitle: 'Análise do Fluxo de Caixa Financeiro',
      link: '/comex/cashflow',
      icon: analitycs,
    });
  }

  if (permissions.vendemmiaContractManagement) {
    aux.push({
      title: 'Gestão de contratos',
      subTitle: 'Contratos com assinatura digital',
      link: '/vendemmia/contract-management',
      icon: contractManagement,
    });
  }

  if (permissions.vendemmiaSQLReportManagement) {
    aux.push({
      title: 'Relatórios SQL',
      subTitle: 'Emissão de relatórios via consultas SQL',
      link: '/vendemmia/report-generator',
      icon: relatorioTi,
    });
  } else if (permissions.vendemmiaSQLReportView) {
    aux.push({
      title: 'Emissão de relatórios',
      subTitle: 'Relatórios personalizados',
      link: '/vendemmia/report-view',
      icon: relatorioTi,
    });
  }

  // if (permissions.vendemmiaProfitability) {
  //   aux.push({
  //     title: 'Análise de Rentabilidade',
  //     subTitle: 'Análise de Rentabilidade',
  //     link: '/vendemmia/comex/profitability',
  //     icon: kanban,
  //   });
  // }

  if (permissions.vendemmiaBillingDashboard) {
    aux.push({
      title: 'Análise de Faturamento',
      subTitle: 'Análise de Faturamento',
      link: '/vendemmia/comex/billing',
      icon: kanban,
    });
  }
  if (
    !permissions.comex &&
    !permissions.logistic &&
    !permissions.warehouse &&
    !permissions.metrics &&
    !permissions.vendemmiaManagement
  ) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Vendemmia',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Funcionalidades Vendemmia',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

if (permissions.vendemmiaManagement) {
  let aux = [];

  if (permissions.vendemmiaManagementItReports) {
    aux.push({
      title: 'Relatórios de TI',
      subTitle: 'Emissão de relatórios de acesso e ETLs',
      link: '/vendemmia/system-reports',
      icon: relatorioTi,
    });
  }
  if (permissions.vendemmiaManagementUserManagement) {
    aux.push({
      title: 'Usuários do sistema',
      subTitle: 'Gestão de usuários, perfis e acessos',
      link: '/vendemmia/user-management',
      icon: userManagement,
    });
  }
  if (permissions.vendemmiaManagementClientManagement) {
    aux.push({
      title: 'Gestão de clientes',
      subTitle: 'Gerencie o cadastro de clientes',
      link: '/vendemmia/client-management',
      icon: userManagement,
    });
  }
  if (permissions.vendemmiaManagementFaqManagement) {
    aux.push({
      title: 'Perguntas frequentes',
      subTitle: 'Gestão de FAQ para os sistemas',
      link: '/vendemmia/faq',
      icon: defaultIcon,
    });
  }
  if (permissions.vendemmiaManagementPolicy) {
    aux.push({
      title: 'Termos de Uso',
      subTitle: 'Gestão dos Termos para os sistemas',
      link: '/vendemmia/policy/terms-of-use',
      icon: processos,
    });
    aux.push({
      title: 'Politicas de privacidade',
      subTitle: 'Gestão das Políticas para os sistemas',
      link: '/vendemmia/policy/privacy',
      icon: defaultIcon,
    });
  }
  if (permissions.vendemmiaAdditionalFieldsManagement) {
    aux.push({
      title: 'Campos adicionais',
      subTitle: 'Complemento em processos',
      link: '/vendemmia/additional-fields-management',
      icon: aditionalfields,
    });
  }

  if (permissions.vendemmiaNcmManagement) {
    aux.push({
      title: 'Gestão de NCMs',
      subTitle: 'Gerencie para o uso no sistema',
      link: '/vendemmia/ncm-management',
      icon: processos,
    });
  }

  if (!permissions.comex && !permissions.logistic && !permissions.warehouse && !permissions.metrics && !permissions.vendemmia) {
    for (let key in aux) {
      items.push(aux[key]);
    }
  } else {
    let item = {
      title: 'Gestão',
      icon: <MdKeyboardArrowDown color="#EEE7F8" />,
      subTitle: 'Gestão de dados gerais',
      subMenu: [],
    };
    for (let key in aux) {
      item.subMenu.push(aux[key]);
    }
    items.push(item);
  }
}

export const headerLinks = [...items];
