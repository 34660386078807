import permissions from '../../services/permissions';
import requests from '../../services/requests';

import FilterOption from './FilterOption';

const canHideProcess = permissions.canHideProcess;

const filterOptionsProcess = [];

const optionsStates = [
  { label: 'TODOS', value: null },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

const generateFilterOptions = async (entity, modality) => {
  const clients = modality === 'all' ? entity.clients : entity.data;

  return clients.map((item) => {
    if (item.type?.toLowerCase() !== 'filial') {
      return {
        ...item,
        label: `${item.name} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
        documentNumber: item.documentNumber,
      };
    }

    return {
      ...item,
      label: ` ${'\u00A0'} - ${item.name} (${item.documentNumberFormatted})`,
      value: item.identifier,
      qttChildren: item.qttChildren,
      documentNumber: item.documentNumber,
    };
  });
};

//Filtros da Dashbord
const filterOptionsDashboard = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Localidade (armazém)',
      name: 'idArmazem',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'SC - matriz', value: 5 },
        { label: 'SC - filial 1', value: 7 },
        { label: 'SP - filial 1', value: 6 },
      ],
    },
    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Canal',
      'channels',
      data.channels.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Modalidade',
      'transportModalities',
      data.transportModalities.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

//Filtros do Menu > Importação
const filterOptionsComex = async () => {
  const data = await requests.getFilters();

  const clients = await requests.listClients(null, '1', '99999', 'comex');
  const options = await generateFilterOptions(clients);

  const aux = [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Canal',
      'channels',
      data.channels.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Modalidade',
      'transportModalities',
      data.transportModalities.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];

  if (canHideProcess) {
    aux.push(
      new FilterOption('Exibir processos', 'hideProcess', [
        new FilterOption('Somente os visíveis', 0, [], true),
        new FilterOption('Somente os ocultos', 1),
      ])
    );
  }

  return aux;
};

const filterOptionsMapping = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  // Canal
  const channels = data.channels.map((item) => {
    const { description, identifier } = item;
    return new FilterOption(description, identifier);
  });
  channels.push(new FilterOption('Sem canal', 'NO_CHANNEL'));

  //Modalidade
  const modalities = data.transportModalities.map((item) => {
    const { description, identifier } = item;
    return new FilterOption(description, identifier);
  });
  modalities.push(new FilterOption('Sem modalidade', 'NO_MODALITY'));

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },

    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption('Canal', 'channels', channels),
    new FilterOption('Modalidade', 'transportModalities', modalities),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

const filterOptionsBillingAnalytics = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  //Modalidade
  const modalities = data.transportModalities.map((item) => {
    const { description, identifier } = item;
    return new FilterOption(description, identifier);
  });
  modalities.push(new FilterOption('Sem modalidade', 'NO_MODALITY'));

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

//Filtros do Menu > Armazém
const filterOptionsWarehouse = async () => {
  const clients = await requests.listClients(null, '1', '99999', 'wms');
  const options = await generateFilterOptions(clients);

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Localidade (armazém)',
      name: 'idArmazem',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'SC - matriz', value: 5 },
        { label: 'SC - filial 1', value: 7 },
        { label: 'SP - filial 1', value: 6 },
      ],
    },
  ];
};

const filterOptionsNcm = async () => {
  return [
    new FilterOption('Status', 'status', [
      new FilterOption('Ativa', 'ACTIVE', []),
      new FilterOption('Em validação', 'VALIDACAO', []),
      new FilterOption('Descontinuada', 'DISCONTINUED', []),
    ]),
    new FilterOption('Exceção tarifária', 'tariffException', [
      new FilterOption('Com EX Tárifário', 'EX_TARIFF', []),
      new FilterOption('Com EX Tipi', 'EX_TIPI', []),
    ]),
  ];
};

const filterOptionsWarehouseReceipt = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Digitada', 'DIGITADA', []),
      new FilterOption('Cancelada', 'CANCELADA', []),
      new FilterOption('Bloqueada', 'BLOQUEADA', []),
      new FilterOption('Processada', 'PROCESSADA', []),
      new FilterOption('Em carga', 'EM_CARGA', []),
      new FilterOption('Importada', 'IMPORTADA', []),
      new FilterOption('Aguardando nf', 'AGUARDANDO_NF', []),
      new FilterOption('Ag alocação', 'AG_ALOCACAO', []),
    ])
  );
};

const filterOptionsWarehousePicking = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Importado', 'IMPORTADO', []),
      new FilterOption('Separação', 'SEPARACAO', []),
      new FilterOption('Conferência', 'CONFERENCIA', []),
      new FilterOption('Enviado para faturamento', 'ENVIADO_PARA_FATURAMENTO', []),
      new FilterOption('Faturado | Ag. coleta', 'FATURADO', []),
      new FilterOption('Coleta iniciada', 'COLETA_INICIADA', []),
      new FilterOption('Outros', 'OUTROS', []),
      new FilterOption('Cancelado', 'CANCELADO', []),
      new FilterOption('Gerada com corte', 'GERADA_COM_CORTE', []),
    ])
  );
};

const filterOptionsWarehouseExpedition = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat({
    label: 'Destino',
    name: 'uf_destinatario',
    isMultiple: false,
    options: optionsStates,
  });
};

const filterOptionsWarehouseExpeditionDocuments = async () => {
  const clients = await requests.listClients(null, '1', '99999', 'wms');
  const options = await generateFilterOptions(clients);

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
  ];
};

const filterOptionsMetricsWarehouse = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat({
    label: 'Destino',
    name: 'uf_destinatario',
    isMultiple: false,
    options: optionsStates,
  });
};

//Filtros do Menu > Transporte
const filterOptionsCurrentTrips = async () => {
  const clients = await requests.listClients(null, '1', '99999', 'tms');

  const options = await generateFilterOptions(clients);

  const listDriver = await requests.listDrivers({
    filters: {
      role: ['ROLE_DRIVER'],
    },
  });

  const users = listDriver?.data?.map((item) => ({
    ...item,
    label: item.name,
    value: item.identifier,
  }));

  users.unshift({ label: 'Sem motorista vinculado', value: 'NO_DRIVER' });

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Motorista',
      name: 'drivers',
      isMultiple: true,
      options: users,
    },
    // new FilterOption('Modalidade de veículo', 'ownership', [
    //   new FilterOption('Veículo próprio', 'PROPRIO'),
    //   new FilterOption('Veículo Vendemmia', 'VENDEMMIA'),
    //   new FilterOption('Veículo de Terceiros', 'TERCEIRO'),
    // ]),
    // fim de alterar aqui
    new FilterOption('Modalidade', 'modalities', [
      new FilterOption('App do motorista', 'APP_MOTORISTA', []),
      new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
      // new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
      // new FilterOption('Gestão manual', 'MANUAL', []),
    ]),
    new FilterOption('Transportadora', 'shippingCompanyName', [
      new FilterOption('Vendemmia', 'VENDEMMIA', []),
      new FilterOption('Outro', 'OUTRO', []),
    ]),
  ];
};

const filterOptionsTrip = async () => {
  const clients = await requests.listClients(null, '1', '99999', 'tms');

  const options = await generateFilterOptions(clients);

  const listDriver = await requests.listDrivers({
    filters: {
      role: ['ROLE_DRIVER'],
    },
  });

  const users = listDriver?.data?.map((item) => ({
    ...item,
    label: item.name,
    value: item.identifier,
  }));
  users.unshift({ label: 'Sem motorista vinculado', value: 'NO_DRIVER' });

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Motorista',
      name: 'drivers',
      isMultiple: true,
      options: users,
    },
    new FilterOption('Modalidade', 'modalities', [
      new FilterOption('App do motorista', 'APP_MOTORISTA', []),
      new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
      new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
      new FilterOption('Gestão manual', 'MANUAL', []),
    ]),
    new FilterOption('Transportadora', 'shippingCompanyName', [
      new FilterOption('Vendemmia', 'VENDEMMIA', []),
      new FilterOption('Outro', 'OUTRO', []),
    ]),
    new FilterOption('Status', 'status', [
      new FilterOption('Preparação', null, [
        new FilterOption('Recusada', 'REFUSED_BY_DRIVER', []),
        new FilterOption('Em rascunho', 'DRAFT', []),
        new FilterOption('Cadastrada', 'CREATED', []),
        new FilterOption('Pendente', 'SENT_TO_DRIVER', []),
        new FilterOption('Agendada', 'ACCEPTED_BY_DRIVER', []),
        new FilterOption('Cancelada', 'CANCELLED', []),
      ]),
      new FilterOption('Execução', null, [
        new FilterOption('Direcionando para coleta', 'GOING_TO_COLLECT'),
        new FilterOption('Chegou para coleta', 'READ_TO_COLLECT'),
        new FilterOption('Coleta iniciada', 'COLLECTING'),
        new FilterOption('Coleta terminada', 'READ_TO_TRAVEL'),
        new FilterOption('Em viagem', 'TRAVELLING'),
        new FilterOption('Impedida', 'HALTED', []),
      ]),
      new FilterOption('Entrega', null, [
        new FilterOption('Chegada no destino', 'ARRIVED_AT_DESTINATION'),
        new FilterOption('Descarregando', 'UNLOADING'),
        new FilterOption('Cancelada pelo motorista', 'DELIVER_REFUSED'),
        new FilterOption('Recebimento assinado', 'DELIVER_SIGNATURE_COLLECTED'),
        new FilterOption('Recebimento recusada', 'DELIVERED_WITH_SIGNATURE'),
        new FilterOption('No destino', 'COMPLETED'),
      ]),
      new FilterOption('Faturamento', null, [
        new FilterOption('Pagamento liberado', 'PAYMENT_ALLOWED'),
        new FilterOption('Pagamento efetuado', 'PAID'),
        new FilterOption('Pagamento cancelado', 'PAID_CANCELLED'),
      ]),
    ]),
  ];
};

const filterOptionsTripKanban = async () => {
  const clients = await requests.listClients(null, '1', '99999', 'tms');

  const options = await generateFilterOptions(clients);

  const listDriver = await requests.listDrivers({
    filters: {
      role: ['ROLE_DRIVER'],
    },
  });

  const users = listDriver?.data?.map((item) => ({
    ...item,
    label: item.name,
    value: item.identifier,
  }));
  users.unshift({ label: 'Sem motorista vinculado', value: 'NO_DRIVER' });

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Motorista',
      name: 'drivers',
      isMultiple: true,
      options: users,
    },
    new FilterOption('Modalidade', 'modalities', [
      new FilterOption('App do motorista', 'APP_MOTORISTA', []),
      new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
      new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
      new FilterOption('Gestão manual', 'MANUAL', []),
    ]),
    new FilterOption('Transportadora', 'shippingCompanyName', [
      new FilterOption('Vendemmia', 'VENDEMMIA', []),
      new FilterOption('Outro', 'OUTRO', []),
    ]),
  ];
};

const filterOptionsAddTravel = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
  ];
};

const filterOptionsDriver = [
  new FilterOption('Status', 'status', [
    new FilterOption('Ativo', 'ATIVO', []),
    new FilterOption('Dados pendentes', 'PENDENTE'),
    new FilterOption('Aguardando análise', 'EM_ANALISE'),
    new FilterOption('Suspenso', 'INATIVO'),
    new FilterOption('Expirado', 'EXPIRADO'),
    new FilterOption('Não aceito', 'REPROVADO'),
    new FilterOption('Anonimizado', 'ANONIMIZADO'),
  ]),
  new FilterOption('Tipo', 'type', [
    new FilterOption('Colaborador Vendemmia', 'INTERNAL', []),
    new FilterOption('Parceiro externo', 'EXTERNAL', []),
  ]),
];

const filterOptionsVehicle = [
  new FilterOption('Modalidade de veículo', 'ownership', [
    new FilterOption('Veículo próprio', 'PROPRIO'),
    new FilterOption('Veículo Vendemmia', 'VENDEMMIA'),
    new FilterOption('Veículo de Terceiros', 'TERCEIRO'),
  ]),
];

//Filtros do Menu > Vendemmia
const filterQuote = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  const requestedPeople = await requests.listOperationResponsibleUsersAction('operator');
  const dataLogistic = await requests.getUsersLogistic();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Modalidade',
      name: 'modality',
      isMultiple: true,
      options: data?.transportModalities.map((item) => ({
        ...item,
        label: `${item.description} (${item.slug})`,
        value: item.identifier,
      })),
    },
    {
      label: 'Solicitante',
      name: 'requestedPeople',
      isMultiple: true,
      options: requestedPeople?.map((item) => ({
        ...item,
        label: item.name,
        value: item.identifier,
      })),
    },
    {
      label: 'Responsável',
      name: 'responsibleLogisticUser',
      isMultiple: true,
      options: dataLogistic?.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.identifier,
      })),
    },
  ];
};

const filterOptionsQuote = async () => {
  let data = await filterQuote();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Cadastrada', 'CREATED', []),
      new FilterOption('Análise logística', 'ON_REVIEW_LOGISTICS', []),
      new FilterOption('Análise operacional', 'ON_REVIEW_OPERATIONAL', []),
      new FilterOption('Análise cliente', 'ON_REVIEW_CLIENT', []),
      new FilterOption('Aprovado', 'APPROVED', []),
      new FilterOption('Rejeitado', 'REJECTED', []),
      new FilterOption('Cancelado', 'CANCELLED', []),
      new FilterOption('Finalizado', 'FINALIZED', []),
    ])
  );
};

const filterOptionsCreditAnalysis = [
  new FilterOption('Status', 'status', [
    new FilterOption('Consultando', 'PENDING'),
    new FilterOption('Atenção', 'CLEARED'),
    new FilterOption('Liberado', 'ATTENTION'),
    new FilterOption('Restrição', 'REJECTED'),
  ]),
];

const filterOptionsOperationSpecification = async () => {
  return [
    new FilterOption('Status', 'status', [
      new FilterOption('Cadastrado', 'CADASTRADO', []),
      new FilterOption('Assinado', 'ASSINADO', []),
      new FilterOption('Aguardando Assinatura', 'AGUARDANDO_ASSINATURA', []),
      new FilterOption('Rascunho', 'RASCUNHO', []),
    ]),
  ];
};

const filterOptionsTaxInquiry = async () => {
  return [
    new FilterOption('Status', 'status', [
      new FilterOption('Em estudo', 'EM_ESTUDO', []),
      new FilterOption('Em validação', 'EM_VALIDACAO', []),
      new FilterOption('Em uso', 'EM_USO', []),
      new FilterOption('Obsoleta', 'OBSOLETA', []),
      new FilterOption('Cancelada', 'CANCELADA', []),
    ]),
  ];
};

const filterOptionsComexCashflow = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
  ];
};

const filterOptionsContractManagement = async () => {
  return [
    new FilterOption('Status', 'status', [
      new FilterOption('Em rascunho', 'EM_RASCUNHO', []),
      new FilterOption('Cadastrado', 'CADASTRADO', []),
      new FilterOption('Enviado', 'ENVIADO', []),
      new FilterOption('Em assinatura', 'EM_ASSINATURA', []),
      new FilterOption('Assinado', 'ASSINADO', []),
      new FilterOption('Cancelado', 'CANCELADO', []),
      new FilterOption('Expirado', 'EXPIRADO', []),
      new FilterOption('Renovado', 'RENOVADO', []),
    ]),
  ];
};

//Filtros do Menu > Gestão
const filterOptionsManagementUser = async () => {
  const data = await requests.getFilters();

  const options = await generateFilterOptions(data, 'all');

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: options,
    },
    {
      label: 'Tipo de perfil',
      name: 'role',
      defaultValue: null,
      isMultiple: true,
      options:
        data?.roles.length > 0 &&
        data.roles.map((item, index) => ({
          label: `${item.roleHumanReadable}`,
          value: item.role,
        })),
    },
    {
      label: 'Status',
      name: 'status',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Em analise', value: 'EM_ANALISE' },
        { label: 'Aprovado', value: 'APROVADO' },
        { label: 'Reprovado', value: 'REPROVADO' },
        { label: 'Ativo', value: 'ATIVO' },
        { label: 'Inativo', value: 'INATIVO' },
        { label: 'Anonimizado', value: 'ANONIMIZADO' },
        { label: 'Expirado', value: 'EXPIRADO' },
      ],
    },
    {
      label: 'Tipo de vinculo com cliente',
      name: 'auto',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Com todos os clientes vinculados', value: 'ATIVO' },
        { label: 'Clientes vinculados manualmente', value: 'INATIVO' },
      ],
    },
  ];
};

const filterOptionsCountry = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Estado',
      name: 'codeState',
      isMultiple: true,
      options:
        data?.states?.length > 0 &&
        data.states.map((item) => ({
          ...item,
          label: item.code,
          value: item.code,
        })),
    },
    {
      label: 'Vínculos',
      name: 'isBound',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Vínculadas', value: true },
        { label: 'Não vínculadas', value: false },
      ],
    },
  ];
};

export {
  filterOptionsAddTravel,
  filterOptionsBillingAnalytics,
  filterOptionsComex,
  filterOptionsComexCashflow,
  filterOptionsContractManagement,
  filterOptionsCountry,
  filterOptionsCreditAnalysis,
  filterOptionsCurrentTrips,
  filterOptionsDashboard,
  filterOptionsDriver,
  filterOptionsManagementUser,
  filterOptionsMapping,
  filterOptionsMetricsWarehouse,
  filterOptionsNcm,
  filterOptionsOperationSpecification,
  filterOptionsProcess,
  filterOptionsQuote,
  filterOptionsTaxInquiry,
  filterOptionsTrip,
  filterOptionsTripKanban,
  filterOptionsVehicle,
  filterOptionsWarehouse,
  filterOptionsWarehouseExpedition,
  filterOptionsWarehouseExpeditionDocuments,
  filterOptionsWarehousePicking,
  filterOptionsWarehouseReceipt,
};
