import { replaceIdentifier } from '../replace/replaceIdentifier';

export const handleDownloadDocument = (endpoint, fileIdentifier, uploadName) => {
  const link = document.createElement('a');

  const url = replaceIdentifier(endpoint, fileIdentifier);

  link.href = (process.env.REACT_APP_API_URL + url).replace('//open', '/open');
  link.target = '_blank';
  link.download = uploadName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
