export const buttonStyles = {
  variants: {
    primary: {
      height: '40px',
      bg: 'primary',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { bg: 'violet2' },
      _focus: { bg: 'primary', boxShadow: 'none' },
    },

    secundary: {
      height: '40px',
      bg: 'transparent',
      color: 'linkColor',
      border: '1px',
      fontSize: '16px',
      borderColor: 'linkColor',
      borderRadius: '9px',
      _hover: { bg: 'lightViolet', opacity: '1' },
      _focus: { color: 'primary', border: '2px', boxShadow: 'none' },
    },

    panelTabsButton: {
      bg: '#F2E9FFAB',
      color: 'linkColor',
      fontSize: '16px',
      borderColor: 'linkColor',
      _hover: { bg: 'lightViolet', opacity: '1' },
      _focus: { color: 'primary', boxShadow: 'none' },
    },

    floatingGreenButton: {
      p: '1.5rem 2rem 1.5rem 2.3rem',
      borderRadius: '50px',
      bg: 'green',
    },

    reset: {
      h: 'initial',
      bg: 'transparent',
      border: 'none',
      display: 'grid',
      alignItems: 'initial',
      justifyContent: 'initial',
      fontWeight: '400',
      w: 'full',
      p: 'initial',
    },

    commonTable: {
      bg: 'bgActionButton',
      color: 'linkColor',
      width: 'initial',
      h: 'initial',
      bg: 'transparent',
      border: 'none',
      display: 'grid',
      alignItems: 'initial',
      justifyContent: 'initial',
      fontWeight: '400',
      w: 'initial',
      p: 'initial',
    },

    caution: {
      height: '40px',
      bg: 'red',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { bg: 'lightRed' },
      _focus: { bg: 'red', boxShadow: 'none' },
    },

    clear: {
      height: '40px',
      bg: 'green',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { opacity: '0.8' },
      _focus: { bg: 'green', boxShadow: 'none' },
    },
  },
};
