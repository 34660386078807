import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CollectionStatusChart from '../../../components/Charts/Warehouse/CollectionStatusChart';
import OrderProgressChart from '../../../components/Charts/Warehouse/OrderProgressChart';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { generateExportFile } from '../../../utils/actions/generateExportFile';
import FilterOption from '../../../utils/filters/FilterOption';
import { filterOptionsWarehousePicking } from '../../../utils/filters/filterPresets';
import { findItemByIdentifier } from '../../../utils/find/findItemByIdentifier';

import { commonListConfig } from './components/commonListConfig';
import PickingPackingTotalProducts from './components/PickingPackingTotalProducts';

const PickingPackingPage = () => {
  const hasPermission = permissions.warehousePickingAndPacking;

  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();
  const [chartData, setChartData] = useState({});

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  const [hasChartChanges, sethasChartChanges] = useState(false);

  const [statusGrafico, setStatusGrafico] = useState(null);
  const [collectionStatus, setCollectionStatus] = useState([]);

  const load = async (filters, key, page) => {
    setIsLoading(true);

    filters.type_storage = 'picking';

    var filters = {
      ...filters,
      statusGrafico,
    };

    try {
      const res = await requests.listPickingPack(filters, page);
      if (request.current && request.current > key) {
        return;
      }
      request.current = key;

      setList(res.data);
      setSublist(res.data);
      setMetadata(res.meta);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading list:', error);
      setIsLoading(false);
    }
  };

  const loadCharts = async (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    filters.type_storage = 'picking';

    try {
      const data = await requests.dashboardPickingPack(filters, 'picking');
      if (requestChart.current && requestChart.current > key) {
        return;
      }
      requestChart.current = key;

      setChartData(data.data);
    } catch (error) {
      console.error('Error loading charts:', error);
    } finally {
      setIsLoadingChart(false);
    }
  };

  const handleDelete = async (identifier) => {
    const item = findItemByIdentifier(list, identifier);

    setIsLoading(true);
    try {
      await requests.deletePickingPack(identifier);
      toast.success('Picking & Pack de nota ' + item.idNotafiscal + ' removido com sucesso!');
    } catch (error) {
      toast.error('Erro ao excluir a nota, tente novamente!');
    } finally {
      setIsLoading(false);
    }
  };

  const exportListFABActionPickingPacking = (filters, key) => {
    //type - optional
    filters.type_storage = 'picking';

    var filters = {
      ...filters,
      statusGrafico,
    };

    //preset
    const endpoint = `/pickingepacking/export/`;
    const fileName = `PickingPacking`;
    const extension = `xlsx`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const exportListFABActionPickingPackingExpedicao = (filters, key) => {
    //type - optional
    filters.type_storage = 'both';

    //preset
    const endpoint = `/pickingepacking/export/`;
    const fileName = `PickingPacking-e-Expedicao`;
    const extension = `xlsx`;
    const method = 'GET';
    const body = {};
    const params = filters;

    //generate file
    return generateExportFile(endpoint, fileName, extension, method, body, params);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehousePicking());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    const filteredChildren = filterOptions.filter((item) => item instanceof FilterOption).map((item) => item.children);

    setCollectionStatus(filteredChildren);
  }, [filterOptions]);

  useEffect(() => {
    sethasChartChanges(!hasChartChanges);
  }, [statusGrafico]);

  const chartsView = [
    {
      title: 'Status dos pedidos',
      content: <CollectionStatusChart data={chartData} setStatusGrafico={setStatusGrafico} />,
    },
    {
      title: 'Status de coleta',
      content: <OrderProgressChart data={chartData} collectionStatus={collectionStatus} filterOptions={filterOptions} type="status" />,
    },
    {
      title: 'Soma dos valores fiscais',
      content: <PickingPackingTotalProducts data={chartData} />,
    },
  ];

  return (
    <Page
      screen="warehouse-picking"
      title="Picking e Packing"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      hasChartChanges={hasChartChanges}
      FAB={[
        {
          title: 'Exportar Picking & Packing',
          text: 'Exportar arquivo CSV com dados de Picking & Packing',
          action: exportListFABActionPickingPacking,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo CSV com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="10px" m="10px">
        <Flex w="full" gap="10px" h={{ base: 'initial', md: '345px' }}>
          {!isMobile ? (
            chartsView.map((item, index) => (
              <Card w="full" title={item.title} key={index}>
                <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
              </Card>
            ))
          ) : (
            <Accordion w="full" gap="10px" display="grid">
              {chartsView.map((item, index) => {
                return (
                  <AccordionItem key={index}>
                    <Card w="full">
                      <AccordionButton display="flex" justifyContent="space-between">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <ScreenLoader isLoading={isLoadingChart}>
                        <AccordionPanel>{item.content}</AccordionPanel>
                      </ScreenLoader>
                    </Card>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Flex>

        <CommonList
          //lists
          list={list}
          rawData={list}
          subList={sublist}
          //actions
          action={action}
          sorting={sorting}
          metadata={metadata}
          setAction={setAction}
          setMetadata={setMetadata}
          handleSort={handleSort}
          deleteButtonAction={handleDelete}
          //loading
          isLoading={isLoading}
          //props
          {...commonListConfig}
        />
      </Flex>
    </Page>
  );
};

export default PickingPackingPage;
