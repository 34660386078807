import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { Button, Flex, FormLabel, Input, Text } from '@chakra-ui/react';

import BackgroundLogin from '../../../components/Login/BackgroundLogin/BackgroundLogin';

const SignaturePage = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const dataState = location.state;

  const addressOptions = [
    { value: 'Rua Funchal, 129 – 13° Andar São Paulo, SP, Brasil, CEP: 04551-060', label: 'São Paulo (Vila Olímpia)' },
    { value: 'Av Marcos Conder, 805 – 8° Andar -  Centro – Itajaí, SC, Brasil, CEP: 88301-123', label: 'Santa Catarina (Itajaí)' },
    {
      value: 'Rodovia BR 470 – KM 7, 6800 - Volta Grande, Navegantes/SC CEP: 88371-890',
      label: 'Armazém SC CD1 (BR 470 – KM 7, 6800)',
    },
    { value: 'Rodovia BR 470 – 4669 - Volta Grande, Navegantes/SC CEP: 88371-890', label: 'Armazém SC CD2 (BR 470 – 4669)' },
    { value: 'Avenida Portugal, 325 - Bairro Itaqui, Itapevi', label: 'Armazém SP (Itapevi)' },
  ];

  return (
    <BackgroundLogin showLogo={false}>
      <Formik
        initialValues={
          dataState
            ? dataState
            : {
                name: '',
                role: '',
                email: '',
                phone: '',
                address: '',
              }
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          window.localStorage.setItem('data-signature', JSON.stringify(values));
          navigate('/signature/result', { state: values });
        }}>
        {({ handleSubmit, isSubmitting, handleChange, setFieldValue, values }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Flex direction="column" className="down-anim">
              <Text textStyle="subtitle" textColor="linkColor" mb="20px">
                Assinatura
              </Text>

              <Flex direction="column" gap="20px">
                <Flex direction="column">
                  <FormLabel className="up-anim" color="white" m="0">
                    Nome
                  </FormLabel>

                  <Input
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    name="name"
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    _placeholder={{ color: '#FFFFFF' }}
                    value={values.name}
                    onChange={handleChange}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="name" />
                </Flex>

                <Flex direction="column">
                  <FormLabel className="up-anim" color="white" m="0">
                    Cargo
                  </FormLabel>

                  <Input
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    name="role"
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    _placeholder={{ color: '#FFFFFF' }}
                    value={values.role}
                    onChange={handleChange}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="role" />
                </Flex>

                <Flex direction="column">
                  <FormLabel className="up-anim" color="white" m="0">
                    E-mail
                  </FormLabel>

                  <Input
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    name="email"
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    _placeholder={{ color: '#FFFFFF' }}
                    value={values.email}
                    onChange={handleChange}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="email" />
                </Flex>

                <Flex direction="column">
                  <FormLabel className="up-anim" color="white" m="0">
                    Telefone
                  </FormLabel>

                  <Input
                    variant="flushed"
                    h="40px"
                    color="#FFFFFF"
                    name="phone"
                    _focus={{ borderColor: '#FFFFFF', boxShadow: '#FFFFFF' }}
                    _placeholder={{ color: '#FFFFFF' }}
                    value={values.phone}
                    onChange={handleChange}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="phone" />
                </Flex>

                <Flex direction="column">
                  <Select
                    placeholder="Selecione um endereço"
                    name="address"
                    options={addressOptions}
                    defaultValue={addressOptions.find((e) => e.value === values?.address)}
                    onChange={(option) => setFieldValue('address', option?.value)}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="email" />
                </Flex>
              </Flex>

              <Flex
                w="full"
                mt={{ sm: '20px', lg: '30px' }}
                gap={{ sm: '0px', lg: '15px' }}
                justify={{ sm: 'space-between', lg: 'end' }}
                className="down-anim">
                <Button
                  variant="outline"
                  height="40px"
                  borderRadius="5px"
                  borderColor="#FF2F69"
                  textColor="#FF2F69"
                  transition=".3s"
                  _hover={{ borderColor: '#FF2F69', opacity: '0.5' }}
                  isDisabled={isSubmitting}
                  onClick={() => {
                    window.location.href = '/';
                  }}>
                  <Text>Voltar</Text>
                </Button>

                <Button
                  type="submit"
                  variant="solid"
                  height="40px"
                  borderRadius="5px"
                  bgColor="#FF2F69"
                  textColor="#FFFFFF"
                  transition=".3s"
                  _hover={{ backgroundColor: '#FF2F69', opacity: '0.5' }}
                  isLoading={isSubmitting}>
                  <Text>Enviar</Text>
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </BackgroundLogin>
  );
};

export default SignaturePage;
