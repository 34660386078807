import React from 'react';

import { MdHelp } from 'react-icons/md';

import { Box, Grid, GridItem, Tooltip } from '@chakra-ui/react';

import SortableListHeader from './components/SortableListHeader';

const CommonListCategories = ({ children, ...props }) => {
  //default properties
  const categories = props?.categories;

  //responsiveness
  const isMobile = props?.isMobile;

  //length
  const gridTemplateLength = props?.gridTemplateLength;

  //ordering
  const sorting = props?.sorting;
  const handleSort = props?.handleSort;

  //loader
  const isLoading = props?.isLoading;

  return (
    !isMobile && (
      <Grid gridTemplateColumns={`${gridTemplateLength}`} data-stycky>
        {categories &&
          categories.map((category, key) => {
            // Verifica se o category possui um grupo
            const hasGroup = category.group && category.group.length > 0;
            const showOnlyVisibleCategory = category.title;

            return (
              // Renderiza apenas se o category possuir um grupo

              //Alinhar faixa acima do campo
              <React.Fragment key={key}>
                {hasGroup && (
                  <style>
                    {`
                      .has-group-${key}::before{
                        content: "";  
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        width: calc(100% + 15px);
                        height: 2px;
                        background: ${category.group[0].color ?? '0'};
                      }

                      body .has-group-${key}::last-child::before {
                        width: 100%;
                      }
                    `}
                  </style>
                )}

                {showOnlyVisibleCategory && (
                  <GridItem
                    as={'strong'}
                    display={'flex'}
                    textTransform={'uppercase'}
                    fontSize={'13px'}
                    position={'relative'}
                    justifyContent={category.align ?? 'flex-start'}
                    textAlign={category.align ?? 'initial'}
                    className={hasGroup && `has-group-${key}`}>
                    {hasGroup ? (
                      <SortableListHeader
                        markGroupName={category.group[0].name}
                        markGroupColor={category.group[0].color}
                        column={category.field}
                        label={category.title}
                        sorting={sorting}
                        handleSort={handleSort}
                      />
                    ) : (
                      <SortableListHeader column={category.field} label={category.title} sorting={sorting} handleSort={handleSort} />
                    )}

                    {category.tooltip && (
                      <Tooltip label={category.tooltip}>
                        <Box ml="0.325rem">
                          <MdHelp size={15} color="#422C76" />
                        </Box>
                      </Tooltip>
                    )}
                  </GridItem>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    )
  );
};

export default CommonListCategories;
