import { MdHelp } from 'react-icons/md';

import { Box, Flex, Grid, Link, Text, Tooltip } from '@chakra-ui/react';

import { replaceIdentifier } from '../../utils/replace/replaceIdentifier';
import ShortenText from '../../utils/text/format/shortenText';

import CustomButtonsRenderer from './components/CustomButtonsRenderer';
import CustomFieldsRenderer from './components/CustomFieldsRenderer';
import { useCategoriesProps } from './Hooks/useCategoriesProps';

const CommonListItemLineMobile = (
  {
    categories,
    data,
    item,
    rawItem,
    content,
    listIndex,
    tags,
    images,
    imagePreset,
    identifier,
    hasDetailsButton,
    detailsRedirectEndpoint,
    hasDeleteButton,
    deleteButtonAction,
    customButtons,
    customFields,
    maxLengthResume,
  },
  props
) => {
  const redirectToDetailsOnClick = () => {
    window.location.href = replaceIdentifier(detailsRedirectEndpoint, identifier);
  };

  return (
    <Grid
      gridTemplateColumns={'1fr'}
      alignItems={'center'}
      border={'1px solid #70707036'}
      borderRadius={'10px'}
      columnGap={'12px'}
      p={'20px 20px 10px'}
      lineHeight={'40px'}
      boxShadow="sm"
      data-screen-width>
      {/* 
        exibir os dados dinamicamente sem se referir explicitamente a item.algumacoisa 
        e excluir as categorias desejadas 
      */}
      <Flex flexDirection={'column'}>
        <Flex gap={'15px'} flexDir={'column'} align={'flex-start'}>
          {Object.keys(item).map((property, index) => {
            const {
              linkValue,
              currentCategory: category,
              formattedValue,
              type,
            } = useCategoriesProps(categories, property, index, item);

            return (
              <Text
                key={`${property}-${index}`}
                lineHeight={'20px'}
                display={'grid'}
                gridTemplateColumns={`auto auto ${category.tooltip ? 'auto' : ''}`}
                alignItems={'baseline'}
                gap={'10px'}
                fontSize={'14px'}
                data-type={type}>
                <Text as={'strong'} fontSize={'14px'}>
                  {category.title}
                </Text>
                {linkValue && linkValue !== null ? (
                  //caso tenha link - o processIdentifier só é acessivel pela data e só retorna nos campos que possuem process
                  <Link color={'linkColor'} href={replaceIdentifier(linkValue, identifier)} target="_blank" rel="noopener noreferrer">
                    {formattedValue}
                  </Link>
                ) : //caso nao tenha link
                category.count ? (
                  rawItem[category.count].length
                ) : type !== 'text' ? (
                  formattedValue
                ) : (
                  <ShortenText value={formattedValue} maxLength={maxLengthResume} />
                )}
                {category.tooltip && (
                  <Tooltip label={category.tooltip}>
                    <Box ml="0.325rem">
                      <MdHelp size={15} color="#422C76" />
                    </Box>
                  </Tooltip>
                )}
              </Text>
            );
          })}
        </Flex>

        <CustomFieldsRenderer customFields={customFields} data={data} listIndex={listIndex} identifier={identifier} />
        <CustomButtonsRenderer
          hasDetailsButton={hasDetailsButton}
          detailsRedirectEndpoint={detailsRedirectEndpoint}
          hasDeleteButton={hasDeleteButton}
          deleteButtonAction={deleteButtonAction}
          customButtons={customButtons}
          rawItem={rawItem}
          identifier={identifier}
          redirectToDetailsOnClick={redirectToDetailsOnClick}
          content={content}
        />
      </Flex>
    </Grid>
  );
};

export default CommonListItemLineMobile;
