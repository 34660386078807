import { useState } from 'react';

import { InfoWindow, Marker } from '@react-google-maps/api';

import { Box, Text } from '@chakra-ui/react';

import { MaritimeMarkerIcons } from './MaritimeMarkerIcons';

export const MaritimeMarker = ({ path, icon, count }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { createCircleIcon, createPulseIcon, createOriginIcon, createDestinationIcon } = MaritimeMarkerIcons();

  const handleMarkerClick = (waypoint) => {
    setSelectedMarker(waypoint);
  };

  let markerCount = 0;

  return (
    <>
      {path.map((waypoint, index) => {
        const isMarker = waypoint.title;

        if (isMarker) {
          markerCount++;
        }

        switch (icon) {
          case 'pulse':
            return waypoint.marker === '' && <Marker key={index} position={waypoint} icon={createPulseIcon()} />;
          case 'position':
            return (
              <Marker
                key={index}
                position={waypoint}
                icon={{
                  fillColor: '#4B0082',
                  fillOpacity: 0.8,
                  path: 0,
                  scale: 6,
                  strokeWeight: 0.2,
                }}
                onClick={() => handleMarkerClick(waypoint)}
              />
            );
          case 'line':
            return (
              <Marker
                key={index}
                position={waypoint}
                icon={createCircleIcon(count ? count + 1 : markerCount)}
                onClick={() => handleMarkerClick(waypoint)}
              />
            );
          case 'origin':
            return waypoint.marker === 'origin' && <Marker key={index} position={waypoint} icon={createOriginIcon()} />;
          case 'destination':
            return waypoint.marker === 'destination' && <Marker key={index} position={waypoint} icon={createDestinationIcon()} />;

          default:
            return (
              <Marker
                key={index}
                position={waypoint}
                icon={createCircleIcon(count ? count + 1 : markerCount, true)}
                onClick={() => handleMarkerClick(waypoint)}
              />
            );
        }
      })}
      {selectedMarker && (
        <InfoWindow position={selectedMarker} onCloseClick={() => setSelectedMarker(null)} style={{ overflow: 'auto !important' }}>
          <Text className="info-window-content">
            <Box m={0} dangerouslySetInnerHTML={{ __html: selectedMarker.title }} />
            <Box m={0} dangerouslySetInnerHTML={{ __html: selectedMarker.description }} />
          </Text>
        </InfoWindow>
      )}
    </>
  );
};

export default MaritimeMarker;
