import useResponsiveCheck from '../../hooks/useResponsiveCheck';

import CommonListItemLineDesktop from './CommonListItemLineDesktop';
import CommonListItemLineMobile from './CommonListItemLineMobile';

const CommonListItemLine = (props) => {
  const { isMobile } = useResponsiveCheck();

  return isMobile ? <CommonListItemLineMobile {...props} /> : <CommonListItemLineDesktop {...props} />;
};

export default CommonListItemLine;
