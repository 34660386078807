import { MdOpenInNew } from 'react-icons/md';

import { Box, Button, ButtonGroup, Text, Tooltip } from '@chakra-ui/react';

import CustomButtons from '../../../components/CommonList/components/CustomButtons';
import DeleteConfirmationModal from '../../../components/CommonList/components/DeleteConfirmationModal';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';

const CustomButtonsRenderer = (props) => {
  const { isMobile } = useResponsiveCheck();

  const {
    hasDetailsButton,
    hasDeleteButton,
    customButtons,
    rawItem,
    identifier,
    redirectToDetailsOnClick,
    deleteButtonAction,
    content,
  } = props;

  if (!hasDetailsButton && !hasDeleteButton && !customButtons) return null;

  return isMobile ? (
    //mobile
    <ButtonGroup display={'flex'} pt={'10px'} mt={'15px'} borderTop={'1px solid #70707036'} gap={'10px'} data-content-button>
      {customButtons && (
        <CustomButtons buttons={customButtons} buttonProps={rawItem} identifier={identifier} subCategoriesProps={rawItem} />
      )}
      {hasDetailsButton && (
        <Tooltip label={'Ver detalhes'}>
          <Box>
            <Button
              variant={'commonTable'}
              onClick={(e) => {
                e.stopPropagation();
                redirectToDetailsOnClick();
              }}>
              <MdOpenInNew size={20} />
            </Button>
          </Box>
        </Tooltip>
      )}
      {hasDeleteButton && <DeleteConfirmationModal deleteButtonAction={deleteButtonAction} identifier={identifier} />}
    </ButtonGroup>
  ) : (
    //desktop
    <Text display={'flex'} data-content-button>
      <>
        {customButtons && (
          <CustomButtons buttons={customButtons} buttonProps={rawItem} identifier={identifier} subCategoriesProps={rawItem} />
        )}
        {hasDetailsButton && (
          <Tooltip label="Ver detalhes">
            <Button
              variant={'commonTable'}
              onClick={(e) => {
                e.stopPropagation();
                redirectToDetailsOnClick();
              }}>
              <MdOpenInNew size={20} />
            </Button>
          </Tooltip>
        )}
        {hasDeleteButton && (
          <Tooltip label="Excluir">
            <DeleteConfirmationModal deleteButtonAction={deleteButtonAction} identifier={identifier} />
          </Tooltip>
        )}
      </>
      {content}
    </Text>
  );
};

export default CustomButtonsRenderer;
