import React from 'react';

import MapDashboard from '../../../../components/Map/MapDashboard';

const SwitchMaps = ({ process, h, points, lines, information }) => {
  return (
    <MapDashboard
      w="100%"
      h={h}
      processDetails={process}
      points={points}
      lines={lines}
      information={information}
      shouldShowCenterMapButton={false}
      defaultMapModality="origin-to-destination"
      maxZoom={20}
    />
  );
};

export default SwitchMaps;
