import React from 'react';

import { buildPolyline } from '../Helpers/buildPolyline';
import { usePopulateRoutes } from '../Hooks/usePopulateRoutes';

import { AnimatedPolyline } from './AnimatedPolyline';
import { CommonPolyline } from './CommonPolyline';
import { MaritimeMarker } from './MaritimeMarker';

export const MaritimeRoutes = ({ information, updateBounds, showCheckpoints }) => {
  const {
    // Default
    originRender,
    destinationRender,
    positionsRender,
    // Pontos
    pastPathRender,
    currentPathRender,
    futurePathRender,
    //extenções de linhas
    originLineExtender,
    originLineExtenderType,
    destinationLineExtender,
    destinationLineExtenderType,
    // Marcadores
    markerPastRender,
    markerCurrentRender,
    markerFutureRender,
    markerDoneRender,
    // Condições
    hasPastPath,
    hasCurrentPath,
    hasFuturePath,
    hasPastPathCount,
  } = usePopulateRoutes(information);

  const {
    // Default
    markerCurrent,
    markerOrigin,
    markerDestination,
    hasMarkerCurrent,
    isOnlyTwoMarkers,
  } = buildPolyline(markerFutureRender, markerCurrentRender);

  /* useEffect(() => {
    // Atualiza os bounds sempre que os caminhos forem alterados
    const allPoints = [...pastPathRender, ...currentPathRender, ...futurePathRender];
    updateBounds(allPoints);
  }, [pastPathRender, currentPathRender, futurePathRender, updateBounds]); */

  return (
    <>
      {/* Marcadores */}
      {/* origin icons */}
      <MaritimeMarker path={markerPastRender} icon={'origin'} />
      <MaritimeMarker path={markerFutureRender} icon={'origin'} />
      {/* destination icons */}
      <MaritimeMarker path={markerPastRender} icon={'destination'} />
      <MaritimeMarker path={markerFutureRender} icon={'destination'} />
      {/* show markers icons */}
      {showCheckpoints && <MaritimeMarker path={markerDoneRender} icon={'position'} />}
      {/* states */}
      <MaritimeMarker path={markerPastRender} icon={'line'} />
      {!isOnlyTwoMarkers && <MaritimeMarker path={markerCurrentRender} icon={'pulse'} />}
      <MaritimeMarker path={markerFutureRender} count={hasPastPathCount} icon={'dashed'} />

      {/* Polylines */}

      {/* past line */}
      {hasPastPath && <CommonPolyline path={pastPathRender} type={'line'} />}
      {/* extends line  */}
      <CommonPolyline path={originLineExtender} type={originLineExtenderType} />
      <CommonPolyline path={destinationLineExtender} type={destinationLineExtenderType} />
      {/* future line */}
      {hasFuturePath && isOnlyTwoMarkers && hasMarkerCurrent ? (
        //funciona desse outro metodo, apenas com dois pontos: origem, destino(utiliza a posição atual para construir a rota)
        <>
          <CommonPolyline path={markerOrigin} type={'line'} />
          <MaritimeMarker path={markerCurrent} icon={'pulse'} />
          <AnimatedPolyline path={markerDestination} reverse={true} />
        </>
      ) : (
        <AnimatedPolyline path={futurePathRender} />
      )}
    </>
  );
};
