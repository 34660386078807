/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  title titulo do componente (opcional - desejável)
 * @param  subtitle subtitle do componente (opcional)
 * @param  categories categorias da tabela (obrigatório, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  subCategories subcategorias da tabela (opcional, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * @param  tooltipCategories Aplica tooltips com descrição a categorias específicas.
 * @param  hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  customButtons gerar lista de botões personalizados (opcional, exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 * @param  customFields gerar lista de campos personalizados (opcional, exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }]
 * type: 'select | radio'
 * name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
 * action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
 *
 * options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
 * roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
 * bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 *
 * @param  collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), exemplo: ['status', 'situacao']
 * @param  statusPreset necessário para o funcionamento das tags (opcional)
 * @param  rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  list envio da lista a ser exibida (obrigatório)
 * @param  subList renderizar a lista secundária (opcional)
 * @param  pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 * @param  action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 *
 */

import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';

//setup
export const commonListConfig = {
  title: '',
  // Estrutura da tabela
  categories: [
    {
      title: 'Importação',
      /* field: 'e.originModality', */
      value: 'originModality',
    },
    {
      title: 'Nota fiscal',
      value: 'notasFormatted',
      defaultValue: 'Não encontrado',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Status',
      field: 'e.status',
      value: 'status',
      defaultValue: 'Não encontrado',
    },
    {
      title: 'OR',
      field: 'e.idOrdemRecto',
      value: 'idOrdemRecto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Remetente',
      field: 'e.nomeRemetente',
      value: 'nomeRemetente',
    },
    {
      title: 'Destinatário',
      field: 'e.nomeDestinatario',
      value: 'nomeDestinatario',
    },
    {
      title: 'DI',
      field: 'e.idDi',
      value: 'idDi',
      align: 'center',
    },
    {
      title: 'Origem',
      field: 'e.origem',
      value: 'origem',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'ufArmazem',
      align: 'center',
    },
    {
      title: 'Início de recebimento',
      field: 'e.dtInicio',
      value: 'dtInicioFormatted',
      align: 'center',
    },
    {
      title: 'Término de recebimento',
      field: 'e.dtFim',
      value: 'dtFimFormatted',
      align: 'center',
    },
    {
      title: 'Data de conferência',
      field: 'e.conferenceAt',
      value: 'conferenceAtFormatted',
      align: 'center',
    },
    {
      title: 'Volumes',
      field: 'e.qtdVolume',
      value: 'qtdVolumeFormatted',
      align: 'center',
      /* type: 'label', */
    },
    {
      title: 'Processo',
      field: 'e.idProcesso',
      value: 'processCode',
      link: '/comex/process/detail/${identifier}',
      align: 'center',
    },
    {
      title: 'Situação',
      field: 'e.situacao',
      value: 'situacao',
    },
    {
      title: 'Ações',
      align: 'center',
      defaultValue: '',
    },
  ],
  subCategories: [
    {
      title: 'Código',
      value: 'codProduto',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'descricao',
    },
    {
      title: 'Quantidade',
      value: 'qtdeEntradaFormatted',
      align: 'center',
    },
    {
      title: 'Lote',
      value: 'loteFabricacao',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Estado do produto',
      value: 'estadoText',
      align: 'left',
    },
  ],
  mainCategoryMobile: 'notasFormatted',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  identifierKey: 'processIdentifier',
  collapsedContentType: 'table',
  tags: ['status', 'situacao', 'estadoText'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Recebido', value: 'Recebido' },
    { color: '#2ECC71', label: 'Liberado', value: 'Liberado' },
    { color: '#CCA42E', label: 'Em Recebimento', value: 'Em Recebimento' },
    { color: '#CCA42E', label: 'Aguardando NF', value: 'AGUARDANDO NF' },
    { color: '#CCA42E', label: 'Pendente', value: 'PENDENTE' },
    { color: '#E74C3C', label: 'Interrompido', value: 'Interrompido' },
    { color: '#E74C3C', label: 'Bloqueado', value: 'Bloqueado' },
    { color: '#E74C3C', label: 'Cancelada', value: 'CANCELADA' },
    { color: '#E74C3C', label: 'Bloqueada', value: 'BLOQUEADA' },
    { color: '#2ECC71', label: 'Processada', value: 'PROCESSADA' },
    { color: '#2EC5CC', label: 'Importada', value: 'IMPORTADA' },
    { color: '#CC782E', label: 'Ag. Alocação', value: 'AG. ALOCAÇÃO' },
    { color: '#422C76', label: 'Em carga', value: 'EM CARGA' },
    { color: '#B1B1B1', label: 'Digitada', value: 'DIGITADA' },
    { color: '#222222', label: 'Não definido', value: 'STATUS NÃO DEFINIDO' },
  ],
  images: ['originModality'],
  imagePreset: [
    { value: 'API', label: 'Importação via API Sênior', image: api },
    { value: 'FTP', label: 'Importação via FTP Sênior', image: ftp },
    { value: 'OUTROS', label: 'Importação via FTP Sênior', image: ftp },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 18,
  maxLengthResumeChildren: 100,
  // Estilo
  cellRowSize: '1fr',
  resizeRows: '100px 120px 180px 100px 1fr 1fr 150px 120px 100px 150px 150px 150px 100px 120px 180px 130px',
  resizeRowsChildren: '180px 420px 120px 150px 180px',
};
