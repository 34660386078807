import { Box, Flex, Grid, Image, Link, Text, Tooltip } from '@chakra-ui/react';

import { replaceIdentifier } from '../../utils/replace/replaceIdentifier';
import ShortenText from '../../utils/text/format/shortenText';

import CustomButtonsRenderer from './components/CustomButtonsRenderer';
import CustomFieldsRenderer from './components/CustomFieldsRenderer';
import renderArrayValues from './Helpers/renderArrayValues';
import renderObjectArrayValues from './Helpers/renderObjectArrayValues';
import { useCategoriesProps } from './Hooks/useCategoriesProps';

const CommonListItemLineDesktop = ({
  categories,
  data,
  item,
  rawItem,
  content,
  listIndex,
  tags,
  images,
  imagePreset,
  identifier,
  hasDetailsButton,
  detailsRedirectEndpoint,
  hasDeleteButton,
  deleteButtonAction,
  customButtons,
  customFields,
  maxLengthResume,
  gridTemplateLength,
  viewport,
  statusPreset,
}) => {
  //methods
  const redirectToDetailsOnClick = () => {
    window.location.href = replaceIdentifier(detailsRedirectEndpoint, identifier);
  };

  const isArrayOrArrayOfObjects = (value) => {
    return typeof value[0] === 'object' && value[0] !== null ? renderObjectArrayValues(value) : renderArrayValues(value);
  };

  //renders
  const renderLink = (linkValue, content) => (
    <Link color="linkColor" href={replaceIdentifier(linkValue, identifier)} target="_blank" rel="noopener noreferrer">
      {content}
    </Link>
  );

  const renderImage = (value, align) => {
    const preset = imagePreset.find((item) => item.value === value);
    return preset ? (
      <Tooltip label={preset.label}>
        <Image margin={align ?? '0 auto'} maxH={35} minW={35} src={preset.image} />
      </Tooltip>
    ) : (
      <Image margin={align ?? '0 auto'} maxH={35} minW={35} src={value} />
    );
  };

  const renderStatus = (property, value, index) => {
    const status = statusPreset.find((item) => item.value === value);
    return status ? (
      <Text key={`${property}-${index}-status`} bg={status.color} data-status-tag>
        {viewport < 1500 ? (
          <Tooltip label={status.label}>
            <Box>{status.label}</Box>
          </Tooltip>
        ) : (
          status.label
        )}
      </Text>
    ) : null;
  };

  const renderHTMLContent = (htmlContent) => {
    return htmlContent ? <Text dangerouslySetInnerHTML={{ __html: htmlContent }} /> : <Text>No HTML content</Text>;
  };

  //renderização por tipos de valores
  const renderValue = (property, value, index, linkValue, category, formattedValue, align, type, renderHTML) => {
    //images
    if (images && images.includes(property)) {
      return renderImage(value, align);
    }

    //status tag
    if (tags && tags.includes(property)) {
      if (statusPreset) {
        return renderStatus(property, value, index) || (Array.isArray(value) ? isArrayOrArrayOfObjects(value) : null);
      }
      return renderHTML ? (
        <Text dangerouslySetInnerHTML={{ __html: renderHTML }} />
      ) : (
        <ShortenText value={formattedValue} maxLength={maxLengthResume} />
      );
    }

    //array subitem
    if (Array.isArray(value)) {
      return isArrayOrArrayOfObjects(value);
    }

    //html
    if (renderHTML) {
      return formattedValue ?? renderHTMLContent(renderHTML);
    }

    //link
    if (linkValue && linkValue !== null) {
      return renderLink(linkValue, formattedValue);
    }

    //contador
    if (category.count) {
      return rawItem[category.count].length;
    }

    //estilo label
    if (type === 'label') {
      return <Text>{formattedValue}</Text>;
    }

    //default text
    return type !== 'text' ? formattedValue : <ShortenText value={formattedValue} maxLength={maxLengthResume} />;
  };

  return (
    <Grid
      gridTemplateColumns={gridTemplateLength}
      alignItems="center"
      border="1px solid #70707036"
      borderRadius="10px"
      columnGap="15px"
      p="8px 25px"
      lineHeight="40px"
      boxShadow="sm"
      minH="58px"
      fontWeight="500"
      data-screen-width>
      {Object.entries(item).map(([property, value], index) => {
        const {
          linkValue,
          currentCategory: category,
          formattedValue,
          align,
          type,
          renderHTML,
        } = useCategoriesProps(categories, property, index, item, value);

        if (!category.title) return null;

        return (
          <Flex
            key={`${property}-${index}`}
            fontSize="16px"
            lineHeight="20px"
            data-type={type}
            data-email-break={property === 'email'}
            justifyContent={align ? align : 'initial'}>
            {renderValue(property, value, index, linkValue, category, formattedValue, align, type, renderHTML)}
          </Flex>
        );
      })}

      <CustomFieldsRenderer customFields={customFields} data={data} listIndex={listIndex} identifier={identifier} />
      <CustomButtonsRenderer
        hasDetailsButton={hasDetailsButton}
        detailsRedirectEndpoint={detailsRedirectEndpoint}
        hasDeleteButton={hasDeleteButton}
        deleteButtonAction={deleteButtonAction}
        customButtons={customButtons}
        rawItem={rawItem}
        identifier={identifier}
        redirectToDetailsOnClick={redirectToDetailsOnClick}
        content={content}
      />
    </Grid>
  );
};

export default CommonListItemLineDesktop;
