export const checkCoordinateStatus = (coordinate, pastPath, futurePath, currentPath = false) => {
  const existsInPastPath = pastPath.some((item) => item.lat === coordinate.latitude && item.lng === coordinate.longitude);
  const existsInFuturePath = futurePath.some((item) => item.lat === coordinate.latitude && item.lng === coordinate.longitude);

  /* console.log('currentPath: ', currentPath, 'existsInPastPath && existsInFuturePath: ', existsInPastPath, existsInFuturePath); */

  if (existsInFuturePath && !currentPath) {
    return 'dashed';
  } else {
    return 'line';
  }
};
