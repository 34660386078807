/* eslint-disable import/order */
import React, { useLayoutEffect } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import IndexPage from '../pages';
import ReactivationPage from '../pages/auth/Reactivation/ReactivationPage';
import RecoverPage from '../pages/auth/Recover/RecoverPage';
import RegisterPage from '../pages/auth/Register/RegisterPage';
import SignaturePage from '../pages/auth/Signature/SignaturePage';
import SignatureResultPage from '../pages/auth/Signature/components/SignatureResult';
import CalendarPage from '../pages/comex/calendar/CalendarPage';
import CashFlowPage from '../pages/comex/cashflow/CashFlowPage';
import KanbanPage from '../pages/comex/kanban/KanbanPage';
import MappingPage from '../pages/comex/mapping/MappingPage';
import ProcessDetailPage from '../pages/comex/process/ProcessDetailPage';
import ProcessPage from '../pages/comex/process/ProcessPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import Error404Page from '../pages/error/Error404';
import CurrentTripsPage from '../pages/logistic/CurrentTrips/CurrentTripsPage';
import DriverManagementPage from '../pages/logistic/DriverManagement/DriverManagement';
import DriverProfilePage from '../pages/logistic/DriverProfile/DriverProfilePage';
import TravelDetailPage from '../pages/logistic/TravelDetail/TravelDetailPage';
import TravelManagementPage from '../pages/logistic/TravelManagement/TravelManagement';
import VehicleManagement from '../pages/logistic/VehicleManagement/VehicleManagementPage';
import KPIComexPage from '../pages/metrics/KPIComex/KPIComexPage';
import KPILmsPage from '../pages/metrics/KPILms/KPILmsPage';
import KPIWmsPage from '../pages/metrics/KPIWms/KPIWmsPage';
import MetricsAnalyticsPage from '../pages/metrics/MetricsAnalytics/MetricsAnalyticsPage';
import MetricsWarehousePage from '../pages/metrics/MetricsWarehouse/MetricsWarehousePage';
import AdditionalFieldsManagement from '../pages/vendemmia/additional-fields-management/AdditionalFieldsManagement';
import AiAssistantPage from '../pages/vendemmia/ai_assistent/AiAssistantPage';
import BillingDashboardPage from '../pages/vendemmia/billing-dashboard/BillingDashboardPage';
import CertificatePage from '../pages/vendemmia/certificate/CertificatePage';
import ClientManagementPage from '../pages/vendemmia/client-management/ClientManagementPage';
import ModuleContractManagementPage from '../pages/vendemmia/contract-management/ContractManagementPage';
import ContractManagementDetailPage from '../pages/vendemmia/contract-management/components/ContractManagementDetailPage';
import CreditAnalysisPage from '../pages/vendemmia/credit-analysis/CreditAnalysisPage';
import CreditAnalysisDetailPage from '../pages/vendemmia/credit-analysis/components/CreditAnalysisDetailPage';
import FaqPage from '../pages/vendemmia/faq/FaqPage';
import NcmManagementPage from '../pages/vendemmia/ncm-management/NcmManagementPage';
import NcmDetailPage from '../pages/vendemmia/ncm-management/components/NcmDetailPage';
import OperationSpecificationPage from '../pages/vendemmia/operation-specification/OperationSpecificationPage';
import ContractManagementPage from '../pages/vendemmia/operation-specification/components/ContractManagementPage';
import PolicyPrivacyPage from '../pages/vendemmia/policy/privacy/PolicyPrivacyPage';
import TermUsePage from '../pages/vendemmia/policy/term-of-use/TermUsePage';
import ProfitabilityPage from '../pages/vendemmia/profitability/ProfitabilityPage';
import QuoteDetailPage from '../pages/vendemmia/quote/QuoteDetailPage';
import QuotePage from '../pages/vendemmia/quote/QuotePage';
import ReportGeneratorPage from '../pages/vendemmia/report-generator/ReportGeneratorPage';
import ReportViewPage from '../pages/vendemmia/report-view/ReportViewPage';
import SystemReportsPage from '../pages/vendemmia/system-reports/SystemReportsPage';
import TaxInquiryPage from '../pages/vendemmia/tax-inquiry/TaxInquiryPage';
import TaxInquiryDetailPage from '../pages/vendemmia/tax-inquiry/components/TaxInquiryDetailPage';
import UserManagementPage from '../pages/vendemmia/user-management/UserManagementPage';
import ExpeditionPage from '../pages/warehouse/expedition/ExpeditionPage';
import ExpeditionDocumentsPage from '../pages/warehouse/expedition/documents/ExpeditionDocumentsPage';
import PickingPackingPage from '../pages/warehouse/picking-packing/PickingPackingPage';
import ReceiptPage from '../pages/warehouse/receipt/ReceiptPage';
import StockAgingPage from '../pages/warehouse/stock-aging/StockAgingPage';
import StockPage from '../pages/warehouse/stock/StockPage';

const { io } = require('socket.io-client');
// const socket = io(process.env.REACT_APP_AUTH_UR || 'http://localhost:3000');

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <BrowserRouter>
      <Routes>
        {/* Auth Pages */}
        <Route exact path="/" element={<IndexPage />} />
        <Route exact path="/recover" element={<RecoverPage />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/reactivation" element={<ReactivationPage />} />
        <Route exact path="/signature" element={<SignaturePage />} />
        <Route exact path="/signature/result" element={<SignatureResultPage />} />
        {/* Dashboard */}
        <Route path="/dashboard" element={<DashboardPage />} />
        {/* Importação */}
        <Route exact path="/comex/process" element={<ProcessPage />} />
        <Route exact path="/comex/process/detail/:identifier" element={<ProcessDetailPage />} />
        <Route exact path="/comex/kanban" element={<KanbanPage />} />
        <Route exact path="/comex/calendar" element={<CalendarPage />} />
        <Route exact path="/comex/mapping" element={<MappingPage />} />
        <Route exact path="/comex/cashflow" element={<CashFlowPage />} />
        {/* Armazém */}
        <Route exact path="/warehouse/receipt" element={<ReceiptPage />} />
        <Route exact path="/warehouse/stock" element={<StockPage />} />
        <Route exact path="/warehouse/picking-packing" element={<PickingPackingPage />} />
        <Route exact path="/warehouse/expedition" element={<ExpeditionPage />} />
        <Route exact path="/warehouse/expedition/documents" element={<ExpeditionDocumentsPage />} />
        <Route exact path="/warehouse/stock-aging" element={<StockAgingPage />} />

        {/* Transporte */}
        <Route exact path="/logistic/trip" element={<TravelManagementPage />} />
        <Route exact path="/logistic/trip/detail/:identifier" element={<TravelDetailPage />} />
        <Route exact path="/logistic/trip/timeline" element={<CurrentTripsPage />} />
        <Route exact path="/logistic/drivers" element={<DriverManagementPage />} />
        <Route exact path="/logistic/driver/profile/:identifier" element={<DriverProfilePage />} />
        <Route exact path="/logistic/fleet" element={<VehicleManagement />} />
        {/* Métricas */}
        <Route exact path="/metrics/kpi-comex" element={<KPIComexPage />} />
        <Route exact path="/metrics/kpi-warehouse" element={<KPIWmsPage />} />
        <Route exact path="/metrics/kpi-lms" element={<KPILmsPage />} />
        <Route exact path="/metrics/analytics" element={<MetricsAnalyticsPage />} />
        <Route exact path="/metrics/warehouse" element={<MetricsWarehousePage />} />
        {/* Vendemmia */}
        <Route exact path="/vendemmia/quote" element={<QuotePage />} />
        <Route exact path="/vendemmia/quote/new" element={<QuoteDetailPage />} />
        <Route exact path="/vendemmia/quote/detail/:identifier" element={<QuoteDetailPage />} />

        <Route exact path="/vendemmia/operation-specification" element={<OperationSpecificationPage />} />
        <Route exact path="/vendemmia/operation-specification/new" element={<ContractManagementPage />} />
        <Route exact path="/vendemmia/operation-specification/detail/:identifier/:isPreviwer" element={<ContractManagementPage />} />
        <Route exact path="/vendemmia/operation-specification/detail/:identifier" element={<ContractManagementPage />} />

        <Route exact path="/vendemmia/credit-analysis" element={<CreditAnalysisPage />} />
        <Route exact path="/vendemmia/credit-analysis/detail/:identifier" element={<CreditAnalysisDetailPage />} />

        <Route exact path="/vendemmia/contract-management" element={<ModuleContractManagementPage />} />
        <Route exact path="/vendemmia/contract-management/new" element={<ContractManagementDetailPage />} />
        <Route exact path="/vendemmia/contract-management/detail/:identifier" element={<ContractManagementDetailPage />} />

        <Route exact path="/vendemmia/system-reports" element={<SystemReportsPage />} />

        <Route exact path="/vendemmia/faq" element={<FaqPage />} />

        <Route exact path="/vendemmia/policy/privacy" element={<PolicyPrivacyPage />} />
        <Route exact path="/vendemmia/policy/terms-of-use" element={<TermUsePage />} />

        <Route exact path="/vendemmia/comex/billing" element={<BillingDashboardPage />} />
        <Route exact path="/vendemmia/comex/profitability" element={<ProfitabilityPage />} />

        <Route exact path="/vendemmia/ai-assistant" element={<AiAssistantPage />} />
        <Route exact path="/vendemmia/report-generator" element={<ReportGeneratorPage />} />
        <Route exact path="/vendemmia/report-view" element={<ReportViewPage />} />

        <Route exact path="/vendemmia/tax-inquiry" element={<TaxInquiryPage />} />
        <Route exact path="/vendemmia/tax-inquiry/new" element={<TaxInquiryDetailPage />} />
        <Route exact path="/vendemmia/tax-inquiry/detail/:identifier" element={<TaxInquiryDetailPage />} />

        <Route exact path="/vendemmia/certificate" element={<CertificatePage />} />
        <Route exact path="/vendemmia/additional-fields-management" element={<AdditionalFieldsManagement />} />
        <Route exact path="/vendemmia/user-management" element={<UserManagementPage />} />
        <Route exact path="/vendemmia/client-management" element={<ClientManagementPage />} />

        <Route exact path="/vendemmia/ncm-management" element={<NcmManagementPage />} />
        <Route exact path="/vendemmia/ncm-management/new" element={<NcmDetailPage />} />
        <Route exact path="/vendemmia/ncm-management/detail/:identifier" element={<NcmDetailPage />} />
        {/* Error Pages */}
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Pages;
