/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  {String} title titulo do componente (opcional - desejável)
 * @param  {String} subtitle subtitle do componente (opcional)
 * @param  {Object[]} categories categorias da tabela e campos a serem populados, 
 * os campos podem ser ordenados pela ordem desejada(só mudar a posição no objeto), somente os campos escolhidos seram exibidos no processo.
 * 
    * @param {categories} title: é o nome da coluna que será exibido na lista
    * @param {categories} field: é o valor que ativa o filtro da categoria escolhida, exemplo: e.code(o 'e' é padrão para a filtragem)
    * @param {categories} value: deve se usar o valor do campo que vem da API para vincular a categoria criada
    * @param {categories} defaultValue: é o valor padrão de exibição quando a API retorna null/undefined, será exibido o que for adicionado como string, 
    * tambem pode ser usado com valor vazio para casos como o do "Ações" não exibe nenhuma informação na coluna.
    * @param {categories} link: adicionamos link ao campo desejado, o endereço será o endpoint escolhido(pode conter identifier), exemplo: '/comex/process/detail/${identifier}'
    * @param {categories} align: determina o alinhamento do item especifico da tabela.
    * @param {categories} tooltip: Aplica tooltip com descrição na categoria específica. 
 * 
 * @param  {Object[]} subCategories subcategorias da tabela, utiliza os mesmos conceitos de categories.
 * @param  {String} mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * exemplo: [{ category: 'NCM', describe: 'identificador de NCM' }]
 * @param  {Bool} hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  {Bool} hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  {Bool} hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  {Bool} hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  {String} detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {String} detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {Bool} hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  {Function} deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  {Object[]} customButtons gerar lista de botões personalizados (opcional, 
 * exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 * @param  {Object[]} customFields gerar lista de campos personalizados (opcional, 
 * 
    * @param {customFields} customFieldstype: 'select | radio'
    * @param {customFields} name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
    * @param {customFields} action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
    *
    * @param {customFields} options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
    * @param {customFields} roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
    * @param {customFields} bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 
* exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }],
    {
      type: 'radio',
      title: 'Status',
      name: 'statusReadable',
      roles: [
        { label: 'Ativado', condition: 'Ativo', status: true },
        { label: 'Desativado', condition: 'Inativo', status: false },
      ],
      bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' },
      action: handleCustomUserAction,
    },
 * 
 *
 * @param  {String} collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  {Icon} collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  {Array} tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), 
 * exemplo: ['status', 'situacao']
 * @param  {Object[]} statusPreset necessário para o funcionamento das tags (opcional), 
 * exemplo: statusPreset: [{
    // ... definições de cores para tags
     @param {statusPreset} color: define a cor da tag 
     @param {statusPreset} label: define o nome que sera exibido na tag, pode ser qualquer nome
     @param {statusPreset} value: deve ser definido o valor que vem do campo da API, precisa ser exatamente igual para o funcionamento
    },
  ]
 * @param  {Object[]} rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  {Object[]} list envio da lista a ser exibida (obrigatório)
 * @param  {Object[]} subList renderizar a lista secundária (opcional)
 * @param  {String} pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  {String} emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  {Number} maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  {String} cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 * @param  {String} resizeRows determina a largura das cedulas de cada coluna de forma personalizada para o primeiro nivel da lista.
 * @param  {String} resizeRowsChildren determina a largura das cedulas de cada coluna de forma personalizada para o segundo nivel da lista(colapso).
 *
 * @param  {State} action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {State} sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {State} metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {SetState} setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {Function} handleSort metodo que determina a atualização da paginação com o filtro escolhido | necessário para o funcionamento de hasPagination (opcional)
 *
 */

import moment from 'moment';
import { MdHelp } from 'react-icons/md';

import { Box, Text, Tooltip } from '@chakra-ui/react';

import formatDocument from '../../../../utils/fields/masks/formatDocument';

export const commonListConfig = {
  title: 'Especificação operacional',
  categories: [
    {
      title: 'Status',
      value: 'status',
      align: 'center',
    },
    {
      title: 'Filiais',
      value: 'renderHTMLFiliais', // Valor exclusivo
      align: 'center',
      renderHTML: (item) => {
        return (
          <Text display={'flex'} gap="3px" alignItems={'center'} justifyContent={'center'}>
            {item?.registration?.vendemmiaOperationUnit?.length > 0 ? (
              <>
                {item.registration.vendemmiaOperationUnit.length}
                <Tooltip
                  label={(() => {
                    let units = item.registration.vendemmiaOperationUnit;
                    let result = [];
                    for (let i in units) {
                      result.push(units[i].label);
                    }
                    return result.join(', ');
                  })()}>
                  <Box>
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>-</>
            )}
          </Text>
        );
      },
    },
    {
      title: 'CNPJ',
      value: 'companyOperationDocumentNumber',
      type: 'label',
      align: 'center',
      formatted: (value) => formatDocument(value),
    },
    {
      title: 'Razão Social',
      value: 'companyName',
    },
    {
      title: 'Versão',
      value: 'version',
      align: 'center',
      type: 'label',
    },
    {
      title: 'Data de Cadastro',
      value: 'createdAt',
      align: 'center',
      formatted: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Pendências',
      value: 'renderHTMLPendencias', // Valor exclusivo
      align: 'center',
      renderHTML: (item) => {
        switch (item.status) {
          case 'RASCUNHO':
            return null;

          case 'ASSINADO':
            return <Text>Sem pendências</Text>;

          case 'CONFIRMADO':
            return <Text>Aguardando envio para assinatura</Text>;

          case 'CADASTRADO': {
            const { qttPending, whoIsMissingApproval } = item?.responsibleUsers?.status || {};

            return (
              <Text display={'flex'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                {qttPending}
                {qttPending > 0 ? (
                  <Tooltip label={whoIsMissingApproval}>
                    <Box>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                ) : (
                  <Text>sem pendências</Text>
                )}
              </Text>
            );
          }

          case 'AGUARDANDO_ASSINATURA': {
            const { qttPendingSign, whoIsMissingSign } = item?.responsibleUsers?.status || {};

            return (
              <Text display={'flex'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                {qttPendingSign}
                {qttPendingSign > 0 ? (
                  <Tooltip label={whoIsMissingSign}>
                    <Box>
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                ) : (
                  <Text>sem pendências</Text>
                )}
              </Text>
            );
          }

          default:
            return null;
        }
      },
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'companyName',
  hasPagination: true,
  hasCollapsed: false,
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 50,
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/operation-specification/detail/${identifier}/isPreviwer',
  tags: ['status'],
  statusPreset: [
    { color: '#6BDD9E', label: 'Assinado', value: 'ASSINADO' },
    { color: '#F1C40F', label: 'Aguardando assinatura', value: 'AGUARDANDO_ASSINATURA' },
    { color: '#B1B1B1', label: 'Rascunho', value: 'RASCUNHO' },
  ],
  resizeRows: '180px 60px 200px 1fr 160px 180px 160px 120px',
};
